
import { defineComponent, PropType } from "vue";
import store from "@/store";
import i18n from "@/i18n";

export default defineComponent({
  name: "basic-button",
  props: {
    mode: String as PropType<"information" | "game" | "closer" | "skip">,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    controlDisabled(): boolean {
      return store.state.ui.controlDisabled;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    autoGame(): boolean {
      return store.state.ui.autoGame;
    },
    modeCss(): object {
      return {
        "--information": this.mode === "information",
        "--game": this.mode === "game",
        "--closer": this.mode === "closer",
        "--skip": this.mode === "skip",
      };
    },
    lang(): string {
      return i18n.global.locale.value;
    },
    isPearl(): boolean {
      return store.state.currentGameId === "loto-pearl";
    },
  },
  methods: {},
});
