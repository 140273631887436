
import store from "@/store";
import { defineComponent } from "vue";
import router from "@/router";
import { MevahoMode } from "@/types/user";
import { GameId } from "@/types/user";
import IconEyeClosed from "@/icons/IconEyeClosed.vue";
import IconEyeOpened from "@/icons/IconEyeOpened.vue";
import IconInformation from "@/icons/IconInformation.vue";
import IconWidescreenOn from "@/icons/IconWidescreenOn.vue";
import IconWidescreenOff from "@/icons/IconWidescreenOff.vue";
import IconSoundOn from "@/icons/IconSoundOn.vue";
import IconSoundOff from "@/icons/IconSoundOff.vue";
import IconBurger from "@/icons/IconBurger.vue";
import { AudioUtils } from "@/utils";

export default defineComponent({
  name: "menu-top",
  components: {
    IconBurger,
    IconSoundOff,
    IconSoundOn,
    IconWidescreenOff,
    IconWidescreenOn,
    IconInformation,
    IconEyeOpened,
    IconEyeClosed,
  },
  computed: {
    gameId(): GameId {
      return store.state.currentGameId;
    },
    scoreboardMode(): MevahoMode {
      return store.state.userData.settings.mevaho_mode;
    },
    controlDisabled(): boolean {
      return store.state.ui.controlDisabled;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    isSoundOn(): boolean {
      return store.state.userData.settings.sound === "on";
    },
    isBackgroundSoundOn(): boolean {
      return store.state.userData.settings.background_sound === "on";
    },
    isWidescreen(): boolean {
      return store.state.userData?.settings?.widescreen === "on";
    },
    makeTicketDisabled(): boolean {
      return store.state.ui.makeTicketDisabled;
    },
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
    disconnected(): boolean {
      return !store.state.connected;
    },
    isFruitMode(): boolean {
      return store.state.userData.settings.mevaho_mode === "fruit";
    },
    isBonusGame(): boolean {
      return store.state.bonus.isOn;
    },
  },
  methods: {
    openMenu() {
      store.commit("setShowMenu", true);
    },
    openInfo() {
      store.dispatch("toggleModal", "payments");
    },
    switchSoundMode() {
      if (this.isSoundOn || this.isBackgroundSoundOn) {
        // Если хотя бы один из звуков включен, выключаем оба
        store.dispatch("setSound", "off");
        store.dispatch("setBackgroundMusic", "off");
      } else {
        // Если оба звука выключены, включаем оба
        store.dispatch("setSound", "on");
        store.dispatch("setBackgroundMusic", "on");
      }

      AudioUtils.updateSoundState();
    },
    switchWidescreenMode() {
      store.dispatch("setWidescreen", this.isWidescreen ? "off" : "on");
    },
    switchScoreboardMode() {
      const path = router.currentRoute.value.path;
      store.dispatch("setMevahoMode", this.scoreboardMode === "digit" ? "fruit" : "digit");
      if (path === "/doubling") router.push("/");
      if (
        this.scoreboardMode === "digit" &&
        (this.gameId === "loto-pamir-legends" ||
          this.gameId === "loto-pearl" ||
          this.gameId === "loto-misr" ||
          this.gameId === "loto-khoja" ||
          this.gameId === "loto-astrologer" ||
          this.gameId === "loto-kissaho" ||
          this.gameId === "loto-gambusaki-gold-9" ||
          this.gameId === "loto-mevaho-gold")
      ) {
        AudioUtils.stopAllBackgroundMusic();
      } else if (this.scoreboardMode === "fruit" && this.gameId === "loto-pamir-legends") {
        AudioUtils.stopBackgroundMusic("loto-pamir-legends", "main");
        AudioUtils.playBackgroundMusic("loto-pamir-legends", "main");
      } else if (this.scoreboardMode === "fruit" && this.gameId === "loto-pearl") {
        AudioUtils.stopBackgroundMusic("loto-pearl", "main");
        AudioUtils.playBackgroundMusic("loto-pearl", "main");
      } else if (this.scoreboardMode === "fruit" && this.gameId === "loto-misr") {
        AudioUtils.stopBackgroundMusic("loto-misr", "main");
        AudioUtils.playBackgroundMusic("loto-misr", "main");
      } else if (this.scoreboardMode === "fruit" && this.gameId === "loto-khoja") {
        AudioUtils.stopBackgroundMusic("loto-khoja", "main");
        AudioUtils.playBackgroundMusic("loto-khoja", "main");
      } else if (this.scoreboardMode === "fruit" && this.gameId === "loto-astrologer") {
        AudioUtils.stopBackgroundMusic("loto-astrologer", "main");
        AudioUtils.playBackgroundMusic("loto-astrologer", "main");
      } else if (this.scoreboardMode === "fruit" && this.gameId === "loto-kissaho") {
        AudioUtils.stopBackgroundMusic("loto-kissaho", "main");
        AudioUtils.playBackgroundMusic("loto-kissaho", "main");
      } else if (this.scoreboardMode === "fruit" && this.gameId === "loto-gambusaki-gold-9") {
        AudioUtils.stopBackgroundMusic("loto-gambusaki-gold-9", "main");
        AudioUtils.playBackgroundMusic("loto-gambusaki-gold-9", "main");
      } else if (this.scoreboardMode === "fruit" && this.gameId === "loto-mevaho-gold") {
        AudioUtils.stopBackgroundMusic("loto-mevaho-gold", "main");
        AudioUtils.playBackgroundMusic("loto-mevaho-gold", "main");
      }
    },
  },
});
