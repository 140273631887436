import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15751188"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "beetle-scoreboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BeetleCell = _resolveComponent("BeetleCell")!
  const _component_CarpetNice = _resolveComponent("CarpetNice")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scoreboard, (data, idx) => {
      return (_openBlock(), _createBlock(_component_BeetleCell, {
        digit: data,
        animation: _ctx.isWon,
        glow: _ctx.isGlow(idx),
        "is-bonus-in-bonus": _ctx.isBonusInBonus,
        idx: idx
      }, null, 8, ["digit", "animation", "glow", "is-bonus-in-bonus", "idx"]))
    }), 256)),
    _createVNode(_component_CarpetNice)
  ]))
}