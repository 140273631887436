import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-146d659e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "four-point-star" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-nice", _ctx.stylesCss])
  }, [
    (_ctx.blink)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(16, (index) => {
          return _createElementVNode("span", _hoisted_1, "✦")
        }), 64))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: _normalizeClass(["input-nice__value", { ['--small']: _ctx.smallFont, ['--ultraSmall']: _ctx.ultraSmallFont }])
    }, _toDisplayString(_ctx.formatValue), 3)
  ], 2))
}