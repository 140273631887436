
import { defineComponent } from "vue";
import store from "@/store";
import BasicButton from "@/components/Panels/BasicButton.vue";
import BigButton from "@/components/UI/BigButton.vue";

export default defineComponent({
  name: "refuse-doubling",
  components: { BigButton, BasicButton },
  computed: {
    isMaintenance(): boolean {
      return store.state.session.maintenance;
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
    isTakePayout(): boolean {
      return store.state.doubling.isTakePayout;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    disconnected(): boolean {
      return !store.state.connected;
    },
    hidden(): boolean {
      return !this.isTakePayout || this.isMaintenance || this.isBetstop || this.disconnected;
    },
  },
  methods: {},
});
