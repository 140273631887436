
import { defineComponent, PropType, computed, ref, watch } from "vue";
import CombinationItem from "@/components/CombinationItem.vue";
import store from "@/store";
import { TicketCombination } from "@/types/tickets";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.css";

export default defineComponent({
  name: "combination-group",
  components: { CombinationItem, Swiper, SwiperSlide },
  setup() {
    const setCombination = (value: TicketCombination) => store.dispatch("setNewCombination", value);

    const combinations = computed(() => store.getters.ticketCombinations as TicketCombination[]);
    const combination = ref(computed(() => store.getters.newCombination as TicketCombination));
    const uiVersion = computed(() => store.state.uiVersion);
    const isMaintenance = computed(() => store.state.session.maintenance);
    const isBetstop = computed(() => store.state.session.betstop);
    const isControlDisabled = computed(() => store.state.ui.controlDisabled);
    const isMakeTicketDisabled = computed(() => store.state.ui.makeTicketDisabled);
    const disconnected = computed(() => !store.state.connected);

    const scrollToNine = () => {
      const container: any = document.getElementById("combi-9");
      container.scrollIntoView({ behavior: "smooth" });
    };
    const scrollToThird = () => {
      const container: any = document.getElementById("combi-3");
      container.scrollIntoView({ block: "end", behavior: "smooth" });
    };
    const scrollToFirst = () => {
      const container: any = document.getElementById("combi-1");
      container.scrollIntoView({ block: "end", behavior: "smooth" });
    };
    const scrollToFifth = () => {
      const container: any = document.getElementById("combi-5");
      container.scrollIntoView({ block: "end", behavior: "smooth" });
    };
    const scrollToSeven = () => {
      const container: any = document.getElementById("combi-7");
      container.scrollIntoView({ behavior: "smooth" });
    };

    watch(combination, (newCombination) => {
      if (newCombination === "combi-9") {
        scrollToNine();
      } else if (newCombination === "combi-1") {
        scrollToFirst();
      } else if (newCombination === "combi-3") {
        scrollToThird();
      } else if (newCombination === "combi-5") {
        scrollToFifth();
      } else if (newCombination === "combi-7") {
        scrollToSeven();
      }
    });

    return {
      combinations,
      combination,
      uiVersion,
      setCombination,
      isMaintenance,
      isBetstop,
      isControlDisabled,
      isMakeTicketDisabled,
      disconnected,
    };
  },
});
