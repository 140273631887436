import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_BigButton = _resolveComponent("BigButton")!

  return (_ctx.isDesktop)
    ? (_openBlock(), _createBlock(_component_BasicButton, {
        key: 0,
        class: _normalizeClass([{ '--blink': !_ctx.hidden && !_ctx.isDoublingOn }, "refuse-doubling"]),
        mode: "closer",
        disabled: _ctx.hidden
      }, null, 8, ["class", "disabled"]))
    : (_openBlock(), _createBlock(_component_BigButton, {
        key: 1,
        class: _normalizeClass({ '--blink': !_ctx.hidden && !_ctx.isDoublingOn }),
        text: _ctx.$t('refuseDoubling'),
        disabled: _ctx.hidden
      }, null, 8, ["class", "text", "disabled"]))
}