import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11fc4dca"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      alt: "beetle animation",
      src: require(`@/images/beetles/anim-0.webp`),
      class: _normalizeClass(["beetle-magic --melody", { '--disappear': _ctx.melody }])
    }, null, 10, _hoisted_1),
    (_ctx.spin)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          alt: "beetle animation",
          src: require(`@/images/beetles/magic.webp`),
          class: _normalizeClass(["beetle-magic --spin", { '--opacity': _ctx.spin }])
        }, null, 10, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.beetleAnim)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          alt: "beetle animation",
          src: require(`@/images/beetles/${_ctx.beetleAnim}.webp`),
          class: _normalizeClass(["beetle-magic --beetle", [{ '--appear': _ctx.beetle }, `--${_ctx.beetleAnim}`]])
        }, null, 10, _hoisted_3))
      : _createCommentVNode("", true)
  ], 64))
}