import { EventEmitter } from "tsee";
import { MessagePacketTypes, MessagePacket } from "@/types/packets";
import {
  TransportAPI,
  TransportDisconnectCode,
  TransportEventMap,
  TransportState,
} from "./interface";

export class TransportAPIRouter extends EventEmitter<TransportEventMap> implements TransportAPI {
  private fakeTransport: TransportAPI;
  private realTransport: TransportAPI;

  private routesReal: { packet: MessagePacketTypes; transport: "fake" | "real" }[] = [
    { packet: MessagePacketTypes.GET_USER_DATA, transport: "real" },
    { packet: MessagePacketTypes.UPDATE_USER_DATA, transport: "real" },
    { packet: MessagePacketTypes.RESTORE_AUTH, transport: "real" },
    { packet: MessagePacketTypes.GET_SETTINGS, transport: "real" },
    { packet: MessagePacketTypes.GET_PROMO, transport: "real" },
    { packet: MessagePacketTypes.PING, transport: "real" },
    { packet: MessagePacketTypes.SESSION_INFO, transport: "real" },
    { packet: MessagePacketTypes.SIMPLE_AUTH, transport: "real" },
  ];
  private routes: { packet: MessagePacketTypes; transport: "fake" | "real" }[] = [];

  constructor(fakeTransport: TransportAPI, realTransport: TransportAPI) {
    super();
    this.routes = location.href.includes("localapi") ? [] : this.routesReal;

    this.fakeTransport = fakeTransport;
    this.realTransport = realTransport;

    this.fakeTransport.on("message", (packet: MessagePacket) => {
      if ("fake" === this.getTransport(packet)) this.emit("message", packet);
    });

    this.realTransport.on("message", (packet: MessagePacket) => {
      if ("real" === this.getTransport(packet)) this.emit("message", packet);
    });

    this.realTransport.on("connect", () => {
      this.emit("connect");
    });
    this.realTransport.on("disconnect", (code: number) => {
      this.emit("disconnect", code);
    });
    this.realTransport.on("offline", () => {
      this.emit("offline");
    });
  }

  public getState(): TransportState {
    return this.realTransport.getState();
  }

  public disconnect(): void {
    this.realTransport.disconnect(TransportDisconnectCode.NO_HEARTBEAT);
  }

  public disconnectFixIos(code: TransportDisconnectCode) {
    this.realTransport.disconnect(TransportDisconnectCode.NO_HEARTBEAT);
  }

  private getTransport(packet: MessagePacket): "real" | "fake" {
    const route = this.routes.find((el) => el.packet === packet.event);
    return route ? route.transport : "fake";
  }

  send(packet: MessagePacket): void {
    const transport = this.getTransport(packet);
    if (transport === "real") {
      this.realTransport.send(packet);
    } else {
      this.fakeTransport.send(packet);
    }
  }

  public reconnect(): void {
    this.realTransport.reconnect();
  }
}
