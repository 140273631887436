import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ef289b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fruits-gold-scoreboard" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FruitsGoldCell = _resolveComponent("FruitsGoldCell")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scoreboard, (data, idx) => {
      return (_openBlock(), _createBlock(_component_FruitsGoldCell, {
        digit: data,
        animation: _ctx.isWon,
        glow: _ctx.isGlow(idx)
      }, null, 8, ["digit", "animation", "glow"]))
    }), 256)),
    _createElementVNode("div", {
      class: _normalizeClass([{ 'fruits-gold-scoreboard__hidden': !_ctx.showAnimation }, "fruits-gold-scoreboard__carpet-nice"])
    }, [
      _createElementVNode("img", {
        src: require('@/images/backgrounds/clouds-static.webp'),
        class: _normalizeClass(["fruits-gold-scoreboard__item", _ctx.styles])
      }, null, 10, _hoisted_2)
    ], 2)
  ]))
}