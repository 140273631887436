import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/images/bird.webp'


const _withScopeId = n => (_pushScopeId("data-v-11f04636"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "doubling-desktop" }
const _hoisted_2 = { class: "doubling-desktop__group" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "doubling-desktop__bird",
  src: _imports_0,
  alt: "good bird"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoublingHeader = _resolveComponent("DoublingHeader")!
  const _component_InputFlame = _resolveComponent("InputFlame")!
  const _component_InputNice = _resolveComponent("InputNice")!
  const _component_LastBoxes = _resolveComponent("LastBoxes")!
  const _component_DoublingBoxesSilkWay = _resolveComponent("DoublingBoxesSilkWay")!
  const _component_DoublingBoxesMisr = _resolveComponent("DoublingBoxesMisr")!
  const _component_DoublingBoxesPamir = _resolveComponent("DoublingBoxesPamir")!
  const _component_DoublingBoxesAstrologer = _resolveComponent("DoublingBoxesAstrologer")!
  const _component_DoublingBoxesMevahoGold = _resolveComponent("DoublingBoxesMevahoGold")!
  const _component_DoublingBoxes = _resolveComponent("DoublingBoxes")!
  const _component_ControlPanel = _resolveComponent("ControlPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DoublingHeader),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expectedWinnings, (item, idx) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (_ctx.fireproof && idx === _ctx.fireproof)
            ? (_openBlock(), _createBlock(_component_InputFlame, {
                key: 0,
                value: item,
                blink: idx === _ctx.stepCount + 1,
                animation: _ctx.stepCount === _ctx.fireproof
              }, null, 8, ["value", "blink", "animation"]))
            : (_openBlock(), _createBlock(_component_InputNice, {
                key: 1,
                value: item,
                color: idx === _ctx.stepCount ? 'yellow' : '',
                blink: idx === _ctx.stepCount + 1
              }, null, 8, ["value", "color", "blink"]))
        ], 64))
      }), 256))
    ]),
    _createVNode(_component_LastBoxes),
    (!_ctx.isMan)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "doubling-desktop__man",
          src: _ctx.imgMan,
          alt: "man with key"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (!_ctx.isNoBird)
      ? (_openBlock(), _createElementBlock("img", _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.isSilkWay)
      ? (_openBlock(), _createBlock(_component_DoublingBoxesSilkWay, { key: 2 }))
      : (_ctx.isMisr)
        ? (_openBlock(), _createBlock(_component_DoublingBoxesMisr, { key: 3 }))
        : (_ctx.isPamir)
          ? (_openBlock(), _createBlock(_component_DoublingBoxesPamir, { key: 4 }))
          : (_ctx.isAstro)
            ? (_openBlock(), _createBlock(_component_DoublingBoxesAstrologer, { key: 5 }))
            : (_ctx.isMevahoGold)
              ? (_openBlock(), _createBlock(_component_DoublingBoxesMevahoGold, { key: 6 }))
              : (_openBlock(), _createBlock(_component_DoublingBoxes, { key: 7 })),
    _createVNode(_component_ControlPanel, {
      class: "doubling-desktop__panel",
      compact: ""
    })
  ]))
}