import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/images/icons/close-icon-hero-white.svg'
import _imports_1 from '@/images/icons/close-icon-hero.svg'


const _withScopeId = n => (_pushScopeId("data-v-13a6b7fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-refresh" }
const _hoisted_2 = { class: "modal-refresh__title" }
const _hoisted_3 = {
  key: 0,
  class: "modal-refresh__image",
  src: _imports_0,
  alt: "close"
}
const _hoisted_4 = {
  key: 1,
  class: "modal-refresh__image",
  src: _imports_1,
  alt: "close"
}
const _hoisted_5 = { class: "modal-refresh__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicModal = _resolveComponent("BasicModal")!

  return (_openBlock(), _createBlock(_component_BasicModal, {
    open: _ctx.open,
    onClose: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("modalRefresh.noAccess")), 1),
        (_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("img", _hoisted_3))
          : (_openBlock(), _createElementBlock("img", _hoisted_4)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("modalRefresh.try")), 1),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t("modalRefresh.try1")), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t("modalRefresh.try2")), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["open", "onClose"]))
}