
import { defineComponent } from "vue";

export default defineComponent({
  name: "mobile-button",
  props: {
    text: String,
    color: String,
    disabled: Boolean,
  },
  computed: {
    styles(): object {
      return {
        "--yellow": this.color === "yellow",
        "--gold": this.color === "gold",
        "--green": this.color === "green",
        "--pink": this.color === "pink",
        "--red": this.color === "red",
        "--iceblue": this.color === "iceblue",
        "--disabled": this.disabled,
      };
    },
  },
});
