
import { defineComponent } from "vue";
import Mobile from "@/layouts/Mobile.vue";
import Desktop from "@/layouts/Desktop.vue";
import store from "@/store";

export default defineComponent({
  components: { Desktop, Mobile },

  computed: {
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
  },
});
