
import { defineComponent, PropType } from "vue";
import ButtonControl from "@/components/UI/ButtonControl.vue";
import store from "@/store";
import { UIVersion } from "@/version";
import { ChipNominal } from "@/types/settings";

export default defineComponent({
  name: "nominal-group",
  components: {
    ButtonControl,
  },
  props: {
    full: Boolean,
  },
  computed: {
    nominal() {
      return store.getters.newNominal;
    },
    nominals(): ChipNominal[] {
      return store.getters.chipNominals;
    },
    controlDisabled(): boolean {
      return store.state.ui.controlDisabled;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    uiVersion(): UIVersion | undefined {
      return store.state.uiVersion;
    },
    shortNominals(): ChipNominal[] {
      return this.nominals.filter((item, idx) => idx < 5);
    },
    fullNominals(): ChipNominal[] {
      return this.nominals.filter((item, idx) => idx > 4);
    },
    makeTicketDisabled(): boolean {
      return store.state.ui.makeTicketDisabled;
    },
  },
  methods: {
    setColor(idx: number): "pink" | "blue" | "orange" {
      if (idx < 5) return "orange";
      else if (idx > 4 && idx < 10) return "blue";
      else return "pink";
    },
    setNominal(value: number) {
      store.dispatch("setNewNominal", value);
    },
  },
});
