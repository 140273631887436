
import { defineComponent } from "vue";
import store from "@/store";
import BurgerModal from "@/components/Modals/BurgerModal.vue";
import MyButton from "@/components/UI/MyButton.vue";

export default defineComponent({
  name: "modal-reload",
  components: { MyButton, BurgerModal },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile(): boolean {
      return store.state.uiVersion !== "desktop";
    },
  },
  methods: {
    closeModal() {
      store.dispatch("toggleModal", "reload");
      location.reload();
    },
  },
});
