import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-274d531d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scoreboard-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DigitalScoreboard = _resolveComponent("DigitalScoreboard")!
  const _component_FruitScoreboard = _resolveComponent("FruitScoreboard")!
  const _component_FruitsGoldScoreboard = _resolveComponent("FruitsGoldScoreboard")!
  const _component_KhojaScoreboard = _resolveComponent("KhojaScoreboard")!
  const _component_PearlScoreboard = _resolveComponent("PearlScoreboard")!
  const _component_KissahoScoreboard = _resolveComponent("KissahoScoreboard")!
  const _component_SilkWayScoreboard = _resolveComponent("SilkWayScoreboard")!
  const _component_MisrScoreboard = _resolveComponent("MisrScoreboard")!
  const _component_PamirScoreboard = _resolveComponent("PamirScoreboard")!
  const _component_AstroScoreboard = _resolveComponent("AstroScoreboard")!
  const _component_BeetleScoreboard = _resolveComponent("BeetleScoreboard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.scoreboardMode === 'digit')
      ? (_openBlock(), _createBlock(_component_DigitalScoreboard, {
          key: 0,
          scoreboard: _ctx.scoreboard,
          "animation-indexes": _ctx.indexAnimationArray,
          "is-won": _ctx.isWon
        }, null, 8, ["scoreboard", "animation-indexes", "is-won"]))
      : (_ctx.scoreboardMode === 'fruit' && _ctx.gameId === 'loto-mevaho-5')
        ? (_openBlock(), _createBlock(_component_FruitScoreboard, {
            key: 1,
            scoreboard: _ctx.scoreboard,
            "animation-indexes": _ctx.indexAnimationArray,
            "is-won": _ctx.isWon
          }, null, 8, ["scoreboard", "animation-indexes", "is-won"]))
        : (_ctx.scoreboardMode === 'fruit' && _ctx.gameId === 'loto-mevaho-gold')
          ? (_openBlock(), _createBlock(_component_FruitsGoldScoreboard, {
              key: 2,
              scoreboard: _ctx.scoreboard,
              "animation-indexes": _ctx.indexAnimationArray,
              "is-won": _ctx.isWon
            }, null, 8, ["scoreboard", "animation-indexes", "is-won"]))
          : (_ctx.scoreboardMode === 'fruit' && _ctx.gameId === 'loto-khoja')
            ? (_openBlock(), _createBlock(_component_KhojaScoreboard, {
                key: 3,
                scoreboard: _ctx.scoreboard,
                "animation-indexes": _ctx.indexAnimationArray,
                "is-won": _ctx.isWon
              }, null, 8, ["scoreboard", "animation-indexes", "is-won"]))
            : (_ctx.scoreboardMode === 'fruit' && _ctx.gameId === 'loto-pearl')
              ? (_openBlock(), _createBlock(_component_PearlScoreboard, {
                  key: 4,
                  scoreboard: _ctx.scoreboard,
                  "animation-indexes": _ctx.indexAnimationArray,
                  "is-won": _ctx.isWon
                }, null, 8, ["scoreboard", "animation-indexes", "is-won"]))
              : (_ctx.scoreboardMode === 'fruit' && _ctx.gameId === 'loto-kissaho')
                ? (_openBlock(), _createBlock(_component_KissahoScoreboard, {
                    key: 5,
                    scoreboard: _ctx.scoreboard,
                    "animation-indexes": _ctx.indexAnimationArray,
                    "is-won": _ctx.isWon
                  }, null, 8, ["scoreboard", "animation-indexes", "is-won"]))
                : (_ctx.scoreboardMode === 'fruit' && _ctx.gameId === 'loto-silk-way')
                  ? (_openBlock(), _createBlock(_component_SilkWayScoreboard, {
                      key: 6,
                      scoreboard: _ctx.scoreboard,
                      "animation-indexes": _ctx.indexAnimationArray,
                      "is-won": _ctx.isWon
                    }, null, 8, ["scoreboard", "animation-indexes", "is-won"]))
                  : (_ctx.scoreboardMode === 'fruit' && _ctx.gameId === 'loto-misr')
                    ? (_openBlock(), _createBlock(_component_MisrScoreboard, {
                        key: 7,
                        scoreboard: _ctx.scoreboard,
                        "animation-indexes": _ctx.indexAnimationArray,
                        "is-won": _ctx.isWon
                      }, null, 8, ["scoreboard", "animation-indexes", "is-won"]))
                    : (_ctx.scoreboardMode === 'fruit' && _ctx.gameId === 'loto-pamir-legends')
                      ? (_openBlock(), _createBlock(_component_PamirScoreboard, {
                          key: 8,
                          scoreboard: _ctx.scoreboard,
                          "animation-indexes": _ctx.indexAnimationArray,
                          "is-won": _ctx.isWon
                        }, null, 8, ["scoreboard", "animation-indexes", "is-won"]))
                      : (_ctx.scoreboardMode === 'fruit' && _ctx.gameId === 'loto-astrologer')
                        ? (_openBlock(), _createBlock(_component_AstroScoreboard, {
                            key: 9,
                            scoreboard: _ctx.scoreboard,
                            "animation-indexes": _ctx.indexAnimationArray,
                            "is-won": _ctx.isWon
                          }, null, 8, ["scoreboard", "animation-indexes", "is-won"]))
                        : (_openBlock(), _createBlock(_component_BeetleScoreboard, {
                            key: 10,
                            scoreboard: _ctx.scoreboard,
                            "animation-indexes": _ctx.indexAnimationArray,
                            "is-won": _ctx.isWon,
                            "is-bonus-in-bonus": _ctx.isBonusInBonus
                          }, null, 8, ["scoreboard", "animation-indexes", "is-won", "is-bonus-in-bonus"]))
  ]))
}