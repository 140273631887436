
import { defineComponent } from "vue";
import store from "@/store";
import ModalRefresh from "@/components/Modals/ModalRefresh.vue";
import ModalRulesInfo from "@/components/Modals/ModalRulesInfo.vue";
import ModalDraws from "@/components/Modals/ModalDraws.vue";
import ModalConnection from "@/components/Modals/ModalConnection.vue";
import ModalAuthorization from "@/components/Modals/ModalAuthorization.vue";
import ModalReplenish from "@/components/Modals/ModalReplenish.vue";
import ModalReload from "@/components/Modals/ModalReload.vue";
import ModalDoubling from "@/components/Modals/ModalDoubling.vue";
import ModalAutodoubling from "@/components/Modals/ModalAutodoubling.vue";
import ModalPayments from "@/components/Modals/ModalPayments.vue";
import ModalExpired from "@/components/Modals/ModalExpired.vue";
import ModalBetstop from "@/components/Modals/ModalBetstop.vue";
import ModalMaintenance from "@/components/Modals/ModalMaintenance.vue";

export default defineComponent({
  name: "modal-list",
  components: {
    ModalBetstop,
    ModalMaintenance,
    ModalExpired,
    ModalPayments,
    ModalAutodoubling,
    ModalDoubling,
    ModalReload,
    ModalReplenish,
    ModalAuthorization,
    ModalConnection,
    ModalDraws,
    ModalRulesInfo,
    ModalRefresh,
  },
  computed: {
    showModalRefresh(): boolean {
      return store.state.modals.refresh;
    },
    showModalRules(): boolean {
      return store.state.modals.rules;
    },
    showModalDraws(): boolean {
      return store.state.modals.draws;
    },
    showModalConnection(): boolean {
      return store.state.modals.connection;
    },
    showModalAuthorization(): boolean {
      return store.state.modals.authorization;
    },
    showModalReplenish(): boolean {
      return store.state.modals.replenish;
    },
    showModalReload(): boolean {
      return store.state.modals.reload;
    },
    showModalDoubling(): boolean {
      return store.state.modals.doubling;
    },
    showModalAutodoubling(): boolean {
      return store.state.modals.autodoubling;
    },
    showModalPayments(): boolean {
      return store.state.modals.payments;
    },
    showModalExpired(): boolean {
      return store.state.modals.expired;
    },
    showModalBetstop(): boolean {
      return store.state.modals.betstop;
    },
    showModalMaintenance(): boolean {
      return store.state.modals.maintenance;
    },
  },
});
