
import store from "@/store";
import { defineComponent, PropType } from "vue";
import storeEvents from "@/store-events";
import { OPEN_BOX_TIMEOUT } from "@/constants";

export default defineComponent({
  name: "doubling-boxes",
  data() {
    return {
      wonLeft: false,
      wonRight: false,
      lostLeft: false,
      lostRight: false,
      boxSide: "left" as "left" | "right",
      disabled: false,
      openTimeout: undefined as number | undefined,
    };
  },
  created() {
    storeEvents.on("isPlayDoublingWon", this.startAnimation);
    storeEvents.on("nextPlayGame", this.resetData);
    storeEvents.on("lastPlayedGame", this.stopGame);
  },
  computed: {
    cssRight(): object {
      return {
        "--won": this.wonRight,
        "--lost": this.lostRight,
      };
    },
    isMaintenance(): boolean {
      return store.state.session.maintenance;
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    isKhoja(): boolean {
      return store.state.currentGameId === "loto-khoja";
    },
    isKissaho(): boolean {
      return store.state.currentGameId === "loto-kissaho";
    },
    isPearl(): boolean {
      return store.state.currentGameId === "loto-pearl";
    },
  },
  methods: {
    goPlay(box: "left" | "right", wonRight: false | true) {
      this.boxSide = box;
      store.dispatch("applyDoublingGame", box);
      this.wonRight = wonRight;
      store.dispatch("boxesSign", box);
      this.disabled = true;
    },
    startAnimation(isWon: boolean) {
      if (isWon) {
        this.$emit("animating", { isOn: true, short: false });
        this.boxSide === "left" ? (this.wonLeft = true) : (this.wonRight = true);
      } else {
        this.$emit("animating", { isOn: true, short: false });
        this.boxSide === "left" ? (this.lostLeft = true) : (this.lostRight = true);
        this.openTimeout = window.setTimeout(() => {
          this.boxSide === "left" ? (this.wonRight = true) : (this.wonLeft = true);
        }, OPEN_BOX_TIMEOUT);
      }
    },
    resetData() {
      this.wonLeft = false;
      this.wonRight = false;
      this.lostLeft = false;
      this.lostRight = false;
      this.boxSide = "left";
      this.disabled = false;
      window.clearTimeout(this.openTimeout);
      this.$emit("animating", { isOn: false, short: false });
    },
    stopGame() {
      this.resetData();
      this.disabled = true;
    },
  },
  beforeUnmount() {
    storeEvents.off("isPlayDoublingWon", this.startAnimation);
    storeEvents.off("nextPlayGame", this.resetData);
    storeEvents.off("lastPlayedGame", this.stopGame);
    window.clearTimeout(this.openTimeout);
  },
});
