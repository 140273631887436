import { ACtx } from "@/store";
import State from "./state";
import { RESET_ERROR_STATUS_TIMEOUT } from "@/constants";

type ErrorStatus = "gamestop" | "internal-error" | "out-of-limits";

type InformationStatus =
  | "won"
  | "lost"
  | "start"
  | "nominal"
  | "gamestop"
  | "internal-error"
  | "out-of-limits"
  | "combination"
  | "balance"
  | "init"
  | "bonus"
  | "bonus-won"
  | "doubling"
  | "promo-failed";

export type InformationState = {
  status: InformationStatus | undefined;
  error: ErrorStatus | undefined;
};

export const state: InformationState = {
  status: "init",
  error: undefined,
};

let errorTimeout: undefined | number = undefined;

export const mutations = {
  setInformationStatus(state: State, status: InformationStatus | undefined) {
    state.information.status = status;
  },
  resetErrorStatus(state: State) {
    state.information.error = undefined;
  },
  setErrorStatus(state: State, status: ErrorStatus) {
    state.information.error = status;
  },
};

export const actions = {
  startInformationWatcher({ state, commit, dispatch }: ACtx) {
    window.setInterval(() => {
      const error = state.information.error;
      const combination = state.createTicket.combination;
      const nominal = state.createTicket.amount;
      const sumAmount = state.createTicket.sumAmount;
      const controlDisabled = state.ui.controlDisabled;
      const balance = state.userData.balance;
      const currentTicketStatus = state.currentTicket ? state.currentTicket.status : "";
      const isTicketWon = state.ui.isWon;
      const isDoublingMode = state.doubling.isOn;
      const payout = state.ui.lastPayout;
      const autoGame = state.ui.autoGame;
      const bonusGame = state.bonus.isOn;
      const promoIsOn = state.ui.promoIsOn;

      if (error) {
        commit("setInformationStatus", error);
      } else if (bonusGame && isTicketWon) {
        commit("setInformationStatus", "bonus-won");
      } else if (bonusGame) {
        commit("setInformationStatus", "bonus");
      } else if (controlDisabled) {
        commit("setInformationStatus", undefined);
      } else if (combination && !nominal) {
        commit("setInformationStatus", "nominal");
      } else if (!combination && nominal) {
        commit("setInformationStatus", "combination");
      } else if (isDoublingMode) {
        commit("setInformationStatus", "doubling");
      } else if (sumAmount && balance < sumAmount && !promoIsOn) {
        commit("setInformationStatus", "balance");
      } else if (currentTicketStatus === "lost") {
        commit("setInformationStatus", "lost");
      } else if (currentTicketStatus === "won" && payout) {
        commit("setInformationStatus", "won");
      } else if (sumAmount) {
        commit("setInformationStatus", "start");
      }
    }, 200);
  },

  resetErrorStatus({ state, commit, dispatch }: ACtx) {
    window.clearTimeout(errorTimeout);
    commit("resetErrorStatus");
  },

  setErrorStatus({ state, commit, dispatch }: ACtx, status: ErrorStatus) {
    window.clearTimeout(errorTimeout);
    commit("setErrorStatus", status);
    errorTimeout = window.setTimeout(() => {
      dispatch("resetErrorStatus");
    }, RESET_ERROR_STATUS_TIMEOUT);
  },
};

export const getters = {};
