/**
 * Transport protocol message types
 */
export enum MessagePacketTypes {
  SESSION_INFO = "session-info",
  SIMPLE_AUTH = "simple-auth",
  RESTORE_AUTH = "restore-auth",
  GET_USER_DATA = "get-user-data",
  UPDATE_USER_DATA = "update-user-data",
  GET_SETTINGS = "get-settings",
  GET_PROMO = "get-promo",
  MAKE_TICKET = "make-ticket",
  UPDATE_TICKET = "update-ticket",
  DRAW_HISTORY = "draw-history",
  BALANCE_CHANGED = "balance-changed",
  DOUBLING_GAME = "doubling-game",
  PLAY_DOUBLING_GAME = "play-doubling-game",
  GET_LAST_DOUBLING_GAMES = "get-last-doubling-games",
  MAKE_BONUS_TICKET = "make-bonus-ticket",
  CLOSE_SESSION = "close-session",
  PING = "ping",
}

/**
 * Transport protocol packet
 */
export type MessagePacket = {
  event: MessagePacketTypes;
  data: object;
  rid?: string;
  error?: {
    code: number;
    text: string;
    critical: boolean;
  };
};
