
import { defineComponent, PropType } from "vue";
import HighlightingLines from "@/components/Scoreboards/HighlightingLines.vue";
import FruitCell from "@/components/Scoreboards/Fruit-cell.vue";
import CarpetNice from "@/components/Scoreboards/CarpetNice.vue";
import store from "@/store";
import router from "@/router";
import PearlCell from "@/components/Scoreboards/PearlCell.vue";

export default defineComponent({
  name: "pearl-scoreboard",
  components: { PearlCell, CarpetNice, FruitCell, HighlightingLines },
  data() {
    return {};
  },
  props: {
    animationIndexes: {
      type: Array as PropType<number[]>,
      default: [],
    },
    scoreboard: {
      type: Array,
      default: new Array(15),
    },
    isBonusInBonus: {
      type: Boolean,
      default: false,
    },
    isWon: Boolean,
  },
  beforeCreate() {
    const isDoubling = store.state.doubling.isOn;
    if (isDoubling) router.push("/doubling");
  },
  mounted() {},
  computed: {},
  methods: {
    isGlow(idx: number): boolean {
      return this.animationIndexes.includes(idx);
    },
  },
});
