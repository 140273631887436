
import { defineComponent } from "vue";
import { CarpetAnim } from "@/store/ui";
import store from "@/store";

export default defineComponent({
  name: "carpet-nice",
  props: {},
  computed: {
    styles(): object {
      return {
        "--close": this.showAnimation === "close",
        "--open": this.showAnimation === "open",
      };
    },
    showAnimation(): CarpetAnim | undefined {
      return store.state.ui.showAnimation;
    },
  },
  watch: {},
});
