import { createApp } from "vue";
import i18n from "@/i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";

declare const DESIGN_TYPE: "lotosport";
export default DESIGN_TYPE;

try {
  console.log(`design ${DESIGN_TYPE}`);
} catch (err) {
  console.warn(err);
}

const app = createApp(App);

app.use(i18n).use(store).use(router);
app.mount("#app");
