import { IEventEmitter } from "tsee";
import {
  UserData,
  AuthSimpleResult,
  AuthSimple,
  ChangeBalance,
  UserDataUpdate,
} from "@/types/user";
import { MessagePacket, MessagePacketTypes } from "@/types/packets";
import { SettingsState } from "@/types/settings";
import { TicketRequest, TicketResponse, UpdateTicket } from "@/types/tickets";
import { DrawHistoryRequest, DrawHistoryResponse } from "@/types/draws";
import {
  DoublingGame,
  PlayDoublingGameRequest,
  PlayDoublingGameResponse,
  GetLastDoublingGameRequest,
  GetLastDoublingGameResponse,
} from "@/types/doubling";
import { SessionInfoUpdate } from "@/types/session";
import { MakeBonusTicketRequest, MakeBonusTicketResponse } from "@/types/bonus";
import { PromoState } from "@/types/promo";

/** Events type declarations for API */
export type IAPIEventMap = {
  ready: () => void;
  disconnect: (code: number) => void;
  offline: () => void;
  sessionInfo: (data: SessionInfoUpdate) => void;
  updateTicket: (data: UpdateTicket) => void;
  changeBalance: (data: ChangeBalance) => void;
  closeSession: () => void;
};

export type TransportEventMap = {
  message: (packet: MessagePacket) => void;
  connect: () => void;
  disconnect: (code: number) => void;
  // send this event after max reconnect attempts reached
  offline: () => void;
};

export enum TransportState {
  OFFLINE,
  CONNECTED,
  DISCONNECTED,
  RECONNECTING,
}

export enum TransportDisconnectCode {
  NO_HEARTBEAT = 4000,
  CLOSE_API = 4001,
}

export interface TransportAPI extends IEventEmitter<TransportEventMap> {
  send(packet: MessagePacket): void;
  disconnect(code: TransportDisconnectCode): void;
  disconnectFixIos(code: TransportDisconnectCode): void;
  reconnect(): void;
  getState(): TransportState;
}

export interface IAPI extends IEventEmitter<IAPIEventMap> {
  restoreAuthorization(payload: AuthSimple): Promise<AuthSimpleResult>;
  simpleAuth(payload: AuthSimple): Promise<AuthSimpleResult>;
  getUserData(): Promise<UserData>;
  getSettings(): Promise<SettingsState>;
  makeTicket(payload: TicketRequest): Promise<TicketResponse>;
  doublingGame(payload: DoublingGame): Promise<{ result: "OK" }>;
  playDoublingGame(payload: PlayDoublingGameRequest): Promise<PlayDoublingGameResponse>;
  getLastDoublingGames(payload: GetLastDoublingGameRequest): Promise<GetLastDoublingGameResponse>;
  makeBonusTicket(payload: MakeBonusTicketRequest): Promise<MakeBonusTicketResponse>;
  updateUserData(data: UserDataUpdate): Promise<string>;
  getDrawHistory(data: DrawHistoryRequest): Promise<DrawHistoryResponse>;
  getPromo(): Promise<PromoState>;
  reconnect(): Promise<void>;
  disconnect(): void;
  disconnectFixIos(): void;
}
