import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-489b1f4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "nominal-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonControl = _resolveComponent("ButtonControl")!

  return (_ctx.uiVersion === 'desktop')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nominals, (value, idx) => {
          return (_openBlock(), _createBlock(_component_ButtonControl, {
            onClick: ($event: any) => (_ctx.setNominal(value.nominal)),
            color: _ctx.setColor(idx),
            value: value.nominal,
            glow: value.nominal === _ctx.nominal,
            disabled: _ctx.controlDisabled || _ctx.isDoublingOn || _ctx.makeTicketDisabled
          }, null, 8, ["onClick", "color", "value", "glow", "disabled"]))
        }), 256))
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["nominal-mobile", { ['--full']: _ctx.full }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nominals, (value, idx) => {
          return (_openBlock(), _createBlock(_component_ButtonControl, {
            onClick: ($event: any) => (_ctx.setNominal(value.nominal)),
            color: _ctx.setColor(idx),
            value: value.nominal,
            glow: value.nominal === _ctx.nominal,
            disabled: _ctx.controlDisabled || _ctx.isDoublingOn || _ctx.makeTicketDisabled
          }, null, 8, ["onClick", "color", "value", "glow", "disabled"]))
        }), 256))
      ], 2))
}