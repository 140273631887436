
import { defineComponent } from "vue";
import store from "@/store";
import { BonusAnim } from "@/types/bonus";
import { AudioUtils } from "@/utils";
import BeetleMagic from "@/components/Scoreboards/BeetleMagic.vue";

export default defineComponent({
  name: "pearl-cell",
  components: { BeetleMagic },
  props: {
    digit: Number,
    animation: {
      type: Boolean,
      default: false,
    },
    glow: {
      type: Boolean,
      default: false,
    },
    isBonusInBonus: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      required: true,
    },
  },
  computed: {
    styles(): object {
      return {
        "--glow": this.animation && this.glow,
        "--opacity": this.animation && !this.glow,
      };
    },
    bonusAnim(): BonusAnim | undefined {
      return store.state.bonus.animDoublingBonus;
    },
    isAnimTurnOn(): boolean {
      return this.animation && this.glow;
    },
  },
  watch: {
    isAnimTurnOn: function (n) {
      if (n === true && this.bonusAnim && this.digit === 10 && !this.isBonusInBonus) {
        AudioUtils.playSound("getting-free-tickets-short-pearl", 0);
      }
    },
  },
});
