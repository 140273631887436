import State from "./state";

export type MultiButtonMode = "autoGame" | "autoDoubling" | "doubling" | "bonus";

export type CarpetAnim = "open" | "close";

export type UIState = {
  isAppLoaded: boolean;
  isAppLoading: boolean;
  connected: boolean;
  offline: boolean;
  maintenanceMode?: boolean;
  activeTab: string;
  controlDisabled: boolean;
  makeTicketDisabled: boolean;
  lastPayout: number;
  stepPayout: number;
  tempPayout: number;
  isWon: boolean;
  showAnimation: CarpetAnim | undefined;
  autoGame: boolean;
  multiButtonMode: MultiButtonMode;
  isDoublingDisabled: boolean;
  autoMakeTicketBlocked: boolean;
  showBonusModal: { show: boolean; start: boolean };
  showPromoModal: { show: boolean; start: boolean };
  promoIsOn: boolean;
  promoTicketsLeft: number;
  isSkipDisabled: boolean;
  lastStateIsOn: boolean;
  showBonusPersons: boolean;
  showBuyinModal: boolean;
  isBuyinMode: boolean;
};

export const state: UIState = {
  isAppLoaded: false,
  isAppLoading: false,
  connected: false,
  offline: false,
  activeTab: "",
  controlDisabled: false,
  makeTicketDisabled: false,
  lastPayout: 0,
  stepPayout: 0,
  tempPayout: 0,
  isWon: false,
  showAnimation: undefined,
  autoGame: false,
  multiButtonMode: "autoGame",
  isDoublingDisabled: false,
  autoMakeTicketBlocked: false,
  showBonusModal: { show: false, start: true },
  showPromoModal: { show: false, start: true },
  promoIsOn: false,
  promoTicketsLeft: 0,
  isSkipDisabled: false,
  lastStateIsOn: false,
  showBonusPersons: false,
  showBuyinModal: false,
  isBuyinMode: false,
};

export const mutations = {
  setIsAppLoaded(state: State, value: boolean = true) {
    state.ui.isAppLoaded = value;
  },
  setIsSkipDisabled(state: State, value: boolean = false) {
    state.ui.isSkipDisabled = value;
  },
  setAppLoading(state: State, value: boolean = true) {
    state.ui.isAppLoading = value;
  },
  setMaintenanceMode(state: State, value: boolean) {
    state.maintenanceMode = value;
  },
  forceResize(state: State) {
    // increment resize counter; component which need rerender must use resizeTrigger in its :key
    state.resizeTrigger++;
  },
  setShowMenu(state: State, value: boolean) {
    state.showMenu = value;
  },
  setShowBonusModal(state: State, { show, start }: { show: boolean; start: boolean }) {
    state.ui.showBonusModal = { show, start };
  },
  setShowPromoModal(state: State, { show, start }: { show: boolean; start: boolean }) {
    state.ui.showPromoModal = { show, start };
  },
  setShowBuyinModal(state: State, value: boolean) {
    state.ui.showBuyinModal = value;
  },
  setIsBuyinMode(state: State, value: boolean) {
    state.ui.isBuyinMode = value;
  },
  setControlDisabled(state: State, value: boolean) {
    state.ui.controlDisabled = value;
  },
  setLastPayout(state: State, value: number) {
    state.ui.lastPayout = value;
  },
  setStepPayout(state: State, value: number) {
    state.ui.stepPayout = value;
  },
  setTempPayout(state: State, value: number) {
    state.ui.tempPayout = value;
  },
  setIsWon(state: State, value: boolean) {
    state.ui.isWon = value;
  },
  setShowAnimation(state: State, value: CarpetAnim | undefined) {
    state.ui.showAnimation = value;
  },
  setAutoGame(state: State, value: boolean) {
    state.ui.autoGame = value;
  },
  setMakeTicketDisabled(state: State, value: boolean) {
    state.ui.makeTicketDisabled = value;
  },
  setMultiButtonMode(state: State, value: MultiButtonMode) {
    state.ui.multiButtonMode = value;
  },
  setButtonDoublingDisabled(state: State, value: boolean) {
    state.ui.isDoublingDisabled = value;
  },
  setAutoMakeTicketBlocked(state: State, value: boolean) {
    state.ui.autoMakeTicketBlocked = value;
  },
  setPromoIsOn(state: State, value: boolean) {
    state.ui.promoIsOn = value;
  },
  setPromoTicketsLeft(state: State, value: number) {
    state.ui.promoTicketsLeft = value;
  },
  setLastStateIsOn(state: State, value: boolean) {
    state.ui.lastStateIsOn = value;
  },
  setShowBonusPersons(state: State, value: boolean) {
    setTimeout(() => {
      state.ui.showBonusPersons = value;
    }, 1500);
  },
};

export const actions = {};

export const getters = {};
