
import { defineComponent } from "vue";

export default defineComponent({
  name: "input-flame",
  props: {
    value: {
      required: true,
      type: Number,
    },
    smallFont: Boolean,
    opacity: Boolean,
    blink: Boolean,
    ultraSmallFont: Boolean,
    animation: Boolean,
  },
  computed: {
    formatValue(): string {
      return (this.value / 100).toFixed(2);
    },
    stylesCss(): object {
      return {
        "--blink": this.blink,
        "--animation": this.animation,
        "--opacity": this.opacity,
        "--mobile": this.smallFont,
      };
    },
  },
  methods: {},
});
