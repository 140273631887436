import isMobile from "ismobilejs";

const searchParams = new URLSearchParams(window.location.search);

export const IS_MOBILE =
  isMobile().phone ||
  searchParams.has("mobile") ||
  (searchParams.has("viewSize") && searchParams.get("viewSize") === "mobile");
export const DESKTOP_VERSION = !IS_MOBILE;

export type UIVersion = "desktop" | "mobile" | "adaptive";

export function getUIVersion(): UIVersion {
  const width = document.documentElement.clientWidth || window.innerWidth;
  const height = document.documentElement.clientHeight || window.innerHeight;
  console.debug(`Viewport size ${width}x${height}`);
  if (IS_MOBILE && width < 500) return "mobile";
  if (IS_MOBILE && width > height && height < 500) return "mobile"; // mobile in landscape mode
  if (width === 1024 && height > 1240 && height < 1367) return "mobile"; // ipad
  if (width > 881) return "desktop";
  // проверка для width/height 0 на десктопе в некоторых платформах (spotliga/superlive/sgtv)
  if (!width && !height) return "desktop";
  return "adaptive";
}
