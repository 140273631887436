import { api } from "@/api";
import { optionsStorage } from "@/api/options-storage";
import i18n, { seti18nLocale } from "@/i18n";
import { ACtx } from "@/store";
import { GameId, LastState, UserData } from "@/types/user";
import { getRestoreToken, getToken, Metrics, RestoreToken, USER_TOKEN } from "@/utils";
import { DrawHistoryResponse, DrawItem } from "@/types/draws";
import { UpdateTicket } from "@/types/tickets";
import { PLAY_BONUS_AFTER_RECONNECT, TICKET_COMBINATIONS } from "@/constants";

export interface BootstrapState {}

export const state: BootstrapState = {};

export const mutations = {};

export const getters = {};

export const actions = {
  bootstrap({ commit, dispatch, state, getters }: ACtx, token?: string): Promise<void> {
    const navigatorLanguage = navigator.language.slice(0, 2);
    const startLanguage = i18n.global.availableLocales.includes(navigatorLanguage)
      ? navigatorLanguage
      : "en";
    seti18nLocale(startLanguage);

    return new Promise<void>((resolve, reject) => {
      let payload: any;
      try {
        payload = getToken(token);
      } catch (e) {
        reject(e);
      }

      commit("setCurrentGameId", payload.game_id);

      // wait for transport and try auth/re-auth
      api.once("ready", async () => {
        commit("setConnected", true);
        let authResult, userData: UserData, lastDraw: DrawHistoryResponse;

        try {
          authResult = await api.simpleAuth({
            platform_id: payload.platform_id,
            user_token: payload.user_token,
            game_id: payload.game_id,
          });
        } catch (e: any) {
          if (e.code === 12) {
            console.error("Валюта игрока не поддерживается");
          } else {
            dispatch("toggleModal", "refresh");
          }
          api.disconnect();
          return reject(e);
        }

        if (!authResult) {
          return reject(new Error("auth failed: no response"));
        }

        if (authResult.result === "expired") {
          dispatch("toggleModal", "refresh");
          return reject(new Error("auth failed: " + authResult.result));
        }

        // store refresh token
        const ut = btoa(JSON.stringify({ type: "simple", value: payload }));
        optionsStorage.setItem(USER_TOKEN, ut);

        // set language for simple_auth if present
        if (payload && payload.lang) {
          await api.updateUserData({ language: payload.lang });
        }

        // fetch settings & user data
        try {
          // set guest user flag
          commit("setIsGuest", authResult.result === "guest");

          // get platform settings first
          await dispatch("loadSettings");

          // get user data and last draw
          try {
            userData = await api.getUserData();
            lastDraw = await api.getDrawHistory({
              limit: 1,
              offset: 0,
            });
            seti18nLocale(userData.language, userData.fallback_language);
          } catch (e) {
            dispatch("toggleModal", "refresh");
            return reject(e);
          }

          dispatch("updateStoredUserData", userData);
          if (lastDraw.items && lastDraw.items.length > 0) {
            commit("updateCurrentTicketHistory", lastDraw.items[0]);
          }

          if (userData.last_state) {
            await dispatch("restoreLastState", userData.last_state);
            commit("setLastStateIsOn", true);
          }
          if (!userData.last_state && authResult.result !== "guest") {
            await dispatch("loadPromo");
          }
        } catch (e) {
          return reject(e);
        }

        commit("setIsAppLoaded");
        dispatch("startInformationWatcher");

        // если после загрузки система на тех-работах, показываем оповещение
        if (state.maintenanceMode) {
          dispatch("toggleToast", "maintenance");
        }

        resolve();
      });
    });
  },
  async bootstrapAfterReconnect({ commit, dispatch, state }: ACtx) {
    try {
      const payload: RestoreToken = getRestoreToken();
      const { type, value } = payload;

      // Perform re-authentication
      let authResult;
      if (type === "simple") {
        authResult = await api.restoreAuthorization({
          platform_id: value.platform_id,
          user_token: value.user_token,
          game_id: value.game_id,
        });
      }

      // Handle authentication failure
      if (!authResult || authResult.result === "expired") {
        dispatch("toggleModal", "refresh");
        throw new Error(`auth failed: ${authResult?.result || "no response"}`);
      }

      // Commit guest user flag
      commit("setIsGuest", authResult.result === "guest");

      // Update language if available
      if (type === "simple" && value.lang) {
        await api.updateUserData({ language: value.lang });
      }

      // Reload platform settings and fetch user data
      await dispatch("loadSettings");
      const userData: UserData = await api.getUserData();
      seti18nLocale(userData.language, userData.fallback_language);
      dispatch("updateStoredUserData", userData);

      // Restore last state or load the last ticket
      if (userData.last_state) {
        await dispatch("restoreLastState", userData.last_state);
      } else {
        await dispatch("loadLastTicket");
      }
    } catch (error) {
      console.error("bootstrapAfterReconnect error", error);
      dispatch("toggleModal", "refresh");
      return Promise.reject(error);
    }
  },
  restoreLastState({ commit, dispatch, state }: ACtx, data: LastState) {
    const { ticket, bonus_ticket } = data;
    const doublingLength = ticket.doubling_games.length;
    const totalBonusGames = ticket.free_tickets_total;
    const countBonusGame = totalBonusGames - ticket.free_tickets_left;
    const lastDoublingGame = ticket.doubling_games[doublingLength - 1];
    const tempTicket: UpdateTicket = {
      ticket_id: ticket.ticket_id,
      draw_id: ticket.draw_id,
      status: ticket.status,
      amount: ticket.amount,
      amount_per_line: ticket.amount_per_line,
      payout: ticket.payout,
      currency: ticket.currency,
      received: ticket.received,
      processed: ticket.processed,
      combination: ticket.combination,
      calculated: ticket.calculated,
      draw: {
        id: ticket.draw.id,
        numbers: ticket.draw.numbers,
      },
      payout_by_line: {
        lines_payout: [],
        bonus_payout: 0,
      },
      has_free_tickets: false,
      is_free_ticket: ticket.is_free_ticket,
      is_buy_in_ticket: ticket.is_buy_in_ticket,
      has_doubling: false,
    };
    const tempBonusTicket: UpdateTicket | undefined =
      ticket.free_tickets_left > 0 && ticket.free_tickets_total
        ? {
            ticket_id: bonus_ticket ? bonus_ticket.ticket_id : ticket.ticket_id,
            draw_id: bonus_ticket ? bonus_ticket.draw_id : ticket.draw_id,
            status: bonus_ticket ? bonus_ticket.status : ticket.status,
            amount: bonus_ticket ? bonus_ticket.amount : ticket.amount,
            payout: bonus_ticket ? bonus_ticket.payout : ticket.payout,
            currency: bonus_ticket ? bonus_ticket.currency : ticket.currency,
            received: bonus_ticket ? bonus_ticket.received : ticket.received,
            processed: bonus_ticket ? bonus_ticket.processed : ticket.processed,
            combination: bonus_ticket ? bonus_ticket.combination : ticket.combination,
            calculated: bonus_ticket ? bonus_ticket.calculated : ticket.calculated,
            draw: {
              id: bonus_ticket ? bonus_ticket.draw.id : ticket.draw.id,
              numbers: bonus_ticket ? bonus_ticket.draw.numbers : ticket.draw.numbers,
            },
            payout_by_line: {
              lines_payout: [],
              bonus_payout: 0,
            },
            has_free_tickets: false,
            is_free_ticket: bonus_ticket ? bonus_ticket.is_free_ticket : ticket.is_free_ticket,
            has_doubling: false,
          }
        : undefined;
    commit("setShowAnimation", undefined);
    commit("setAutoGame", false);
    if (tempBonusTicket) {
      commit("setIsBonusOn", true);
      commit("setShowBonusPersons", true);
      commit("setCurrentTicket", tempBonusTicket);
      commit("setLastTicket", tempTicket);
      commit("setBonusCount", countBonusGame);
      commit("setNumGames", totalBonusGames);
      commit("setLastPayout", tempTicket.payout);
      commit("setMultiButtonMode", "bonus");
      commit("setControlDisabled", true);
      commit("setIsSkipDisabled", true);
      setTimeout(() => {
        dispatch("makeBonusTicket");
      }, PLAY_BONUS_AFTER_RECONNECT);
    } else {
      commit("setIsDoublingOn", true);
      commit("setCurrentTicket", tempTicket);
      commit("setIsDigitDoublingAnim", true);
      commit("setMultiButtonMode", "doubling");
      commit("updateExpectedWinnings", ticket.payout / 2 ** doublingLength);
      commit("setDigitWinnings", ticket.doubling_games);
      commit("setCountGame", doublingLength);
      if (lastDoublingGame) {
        commit("updateLastGameInfo", lastDoublingGame);
        commit("setLastPayout", lastDoublingGame.payout);
      } else {
        commit("setLastPayout", ticket.payout);
      }
      dispatch("startDoublingTimer");
    }

    commit("setNewNominal", ticket.amount_per_line);
    commit("setSumAmount", ticket.amount);
    commit("setIsBuyinMode", ticket.is_buy_in_ticket);
    // dispatch("setNewNominal",  ticket.amount / TICKET_COMBINATIONS[ticket.combination].array.length);
    dispatch("setNewCombination", ticket.combination);
  },
};
