
import store from "@/store";
import { defineComponent, PropType } from "vue";
import storeEvents from "@/store-events";
import { OPEN_BOX_TIMEOUT } from "@/constants";

export default defineComponent({
  name: "doubling-boxes-pamir",
  data() {
    return {
      wonLeft: false,
      wonRight: false,
      lostLeft: false,
      lostRight: false,
      boxSide: "left" as "left" | "right",
      disabled: false,
      openTimeout: undefined as number | undefined,
      rightWonPamir: require("@/images/ui/pamir-right-won.webp"),
      leftWonPamir: require("@/images/ui/pamir-left-won.webp"),
      rightLostPamir: require("@/images/ui/pamir-right-lost.webp"),
      leftLostPamir: require("@/images/ui/pamir-left-lost.webp"),
      startPamir: require("@/images/ui/pamir-start.png"),
    };
  },
  created() {
    storeEvents.on("isPlayDoublingWon", this.startAnimation);
    storeEvents.on("nextPlayGame", this.resetData);
    storeEvents.on("lastPlayedGame", this.stopGame);
  },
  mounted() {
    this.preloadImages();
  },
  computed: {
    cssRight(): object {
      return {
        "--wonRight": this.wonRight,
        "--lostRight": this.lostRight,
        "--wonLeft": this.wonLeft,
        "--lostLeft": this.lostLeft,
      };
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    currentImage(): string {
      return this.wonRight === true
        ? this.rightWonPamir
        : this.lostRight === true
        ? this.rightLostPamir
        : this.wonLeft === true
        ? this.leftWonPamir
        : this.lostLeft === true
        ? this.leftLostPamir
        : this.startPamir;
    },
  },
  methods: {
    goPlay(box: "left" | "right", wonRight: false | true) {
      this.boxSide = box;

      store.dispatch("applyDoublingGame", box);

      this.wonRight = wonRight;
      store.dispatch("boxesSign", box);
      this.disabled = true;
    },
    startAnimation(isWon: boolean) {
      if (isWon) {
        this.boxSide === "left" ? (this.wonLeft = true) : (this.wonRight = true);
      } else {
        this.boxSide === "left" ? (this.lostLeft = true) : (this.lostRight = true);
      }
    },

    resetData() {
      this.wonLeft = false;
      this.wonRight = false;
      this.lostLeft = false;
      this.lostRight = false;
      this.boxSide = "left";
      this.disabled = false;
      window.clearTimeout(this.openTimeout);
      this.$emit("animating", { isOn: false, short: false });
    },
    stopGame() {
      this.resetData();
      this.disabled = true;
    },
    preloadImages() {
      const images = [
        this.rightWonPamir,
        this.rightLostPamir,
        this.leftWonPamir,
        this.leftLostPamir,
        this.startPamir,
      ];
      images.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    },
  },

  beforeUnmount() {
    storeEvents.off("isPlayDoublingWon", this.startAnimation);
    storeEvents.off("nextPlayGame", this.resetData);
    storeEvents.off("lastPlayedGame", this.stopGame);
    window.clearTimeout(this.openTimeout);
  },
});
