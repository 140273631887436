import { ACtx } from "@/store";
import State from "./state";
import { AudioUtils } from "@/utils";

export type SoundsState = {};

export const state: SoundsState = {};

export const mutations = {};

export const actions = {
  playLineWin({ state, commit, dispatch, getters }: ACtx, mode: "short" | "long") {
    const hasBonus = state.currentTicket?.has_free_tickets;
    const isDoubling = state.doubling.isOn;

    if (mode === "short" && !hasBonus && !isDoubling) {
      AudioUtils.playSound(
        state.userData.settings.mevaho_mode === "digit"
          ? "digit-short"
          : state.currentGameId === "loto-pamir-legends" && state.bonus.isOn
          ? "win-one-pamir-legends-bonus"
          : state.currentGameId === "loto-pamir-legends" && !state.bonus.isOn
          ? "win-one-pamir-legends"
          : state.currentGameId === "loto-pearl"
          ? "win-one-pearl"
          : state.currentGameId === "loto-misr"
          ? "win-one-misr"
          : state.currentGameId === "loto-khoja"
          ? "win-one-khoja"
          : state.currentGameId === "loto-astrologer"
          ? "win-one-astrologer"
          : state.currentGameId === "loto-kissaho"
          ? "win-one-kissaho"
          : state.currentGameId === "loto-gambusaki-gold-9"
          ? "win-one-gambusaki-gold"
          : "fruit-middle",
        0
      );
    } else if (mode === "long" && !hasBonus && !isDoubling) {
      AudioUtils.playSound(
        state.userData.settings.mevaho_mode === "digit"
          ? "receipt-win-drop-sound"
          : state.currentGameId === "loto-pamir-legends" && state.bonus.isOn
          ? "win-three-pamir-legends-bonus"
          : state.currentGameId === "loto-pamir-legends" && !state.bonus.isOn
          ? "win-three-pamir-legends"
          : state.currentGameId === "loto-pearl"
          ? "win-three-pearl"
          : state.currentGameId === "loto-misr"
          ? "win-three-misr"
          : state.currentGameId === "loto-khoja"
          ? "win-three-khoja"
          : state.currentGameId === "loto-astrologer"
          ? "win-three-astrologer"
          : state.currentGameId === "loto-kissaho"
          ? "win-three-kissaho"
          : state.currentGameId === "loto-gambusaki-gold-9"
          ? "win-three-gambusaki-gold"
          : "win-drop-sound",
        0
      );
    }
  },
  playLineWinMevahoGold(
    { state, commit, dispatch, getters }: ACtx,
    mode: "short1" | "long1" | "short2" | "long2" | "short3" | "long3"
  ) {
    const hasBonus = state.currentTicket?.has_free_tickets;
    const isDoubling = state.doubling.isOn;

    if ((mode === "short1" || mode === "short2" || mode === "short3") && !hasBonus && !isDoubling) {
      AudioUtils.playSound(
        state.userData.settings.mevaho_mode === "digit"
          ? "digit-short"
          : mode === "short1"
          ? "sound1-one-mevaho-gold"
          : mode === "short2"
          ? "sound2-one-mevaho-gold"
          : mode === "short3"
          ? "sound3-one-mevaho-gold"
          : "fruit-middle",
        0
      );
    } else if (
      (mode === "long1" || mode === "long2" || mode === "long3") &&
      !hasBonus &&
      !isDoubling
    ) {
      AudioUtils.playSound(
        state.userData.settings.mevaho_mode === "digit"
          ? "receipt-win-drop-sound"
          : mode === "long1"
          ? "sound1-three-mevaho-gold"
          : mode === "long2"
          ? "sound2-three-mevaho-gold"
          : mode === "long3"
          ? "sound3-three-mevaho-gold"
          : "win-drop-sound",
        0
      );
    }
  },
};

export const getters = {};
