
import { defineComponent } from "vue";
import InfoModal from "@/components/Modals/InfoModal.vue";
import store from "@/store";
import MiniCell from "@/components/MiniCell.vue";
import { DrawItem } from "@/types/draws";
import moment from "moment";
import { GameId } from "@/types/user";

export default defineComponent({
  name: "modal-draws",
  components: { MiniCell, InfoModal },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  updated() {
    if (this.open) store.dispatch("loadDrawHistory");
  },
  computed: {
    draws(): DrawItem[] {
      return store.state.draws.drawHistory;
    },
    gameId(): GameId {
      return store.state.currentGameId;
    },
  },
  methods: {
    closeModal() {
      store.dispatch("toggleModal", "draws");
    },
    formatTime(ms: number) {
      return moment(ms).format("DD.MM.YYYY HH:mm:ss");
    },
  },
});
