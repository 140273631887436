
import { defineComponent, PropType } from "vue";
import HighlightingLines from "@/components/Scoreboards/HighlightingLines.vue";
import CarpetNice from "@/components/Scoreboards/CarpetNice.vue";
import store from "@/store";
import router from "@/router";
import PamirCell from "@/components/Scoreboards/PamirCell.vue";

export default defineComponent({
  name: "pamir-scoreboard",
  components: { CarpetNice, PamirCell, HighlightingLines },
  data() {
    return {};
  },
  props: {
    animationIndexes: {
      type: Array as PropType<number[]>,
      default: [],
    },
    scoreboard: {
      type: Array,
      default: new Array(15),
    },
    isBonusInBonus: {
      type: Boolean,
      default: false,
    },
    isWon: Boolean,
  },
  beforeCreate() {
    const isDoubling = store.state.doubling.isOn;
    if (isDoubling) router.push("/doubling");
  },
  mounted() {},
  computed: {
    isBonusGamePamirLegends(): boolean {
      return store.state.ui.showBonusPersons && store.state.currentGameId === "loto-pamir-legends";
    },
  },
  methods: {
    isGlow(idx: number): boolean {
      return this.animationIndexes.includes(idx);
    },
  },
});
