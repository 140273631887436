
import { defineComponent, PropType } from "vue";
import CurrencyIcon from "@/components/CurrencyIcon.vue";
import gsap from "gsap";
import { formatMoney } from "@/utils";

export default defineComponent({
  name: "display-field",
  components: { CurrencyIcon },
  data() {
    return {
      tweened: this.value ? this.value : 0,
    };
  },
  props: {
    title: String,
    value: Number,
    inner: {
      type: Boolean,
      default: false,
    },
    invert: {
      type: Boolean,
      default: false,
    },
    center: {
      default: false,
      type: Boolean,
    },
    showZero: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    formatMoney(): string {
      return formatMoney(this.value || 0, "std");
    },
  },
  // watch: {
  //   value() {
  //     if (this.value) {
  //       gsap.to(this, { duration: DURATION_TWEENED, tweened: this.value || 0 });
  //     }
  //   },
  // },
});
