import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/images/icons/maintenance-icon-white.svg'
import _imports_1 from '@/images/icons/maintenance-icon.svg'


const _withScopeId = n => (_pushScopeId("data-v-89f01720"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-maintenance" }
const _hoisted_2 = { class: "modal-maintenance__title" }
const _hoisted_3 = {
  key: 0,
  class: "modal-maintenance__image",
  src: _imports_0,
  alt: "maintenance"
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1,
  class: "modal-maintenance__image",
  alt: "maintenance"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BurgerModal = _resolveComponent("BurgerModal")!

  return (_openBlock(), _createBlock(_component_BurgerModal, {
    "show-close": true,
    bottom: "",
    open: true,
    onClose: _ctx.closeModal,
    class: _normalizeClass({ '--hidden': !_ctx.open })
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("maintenanceTitle")), 1),
        (_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("img", _hoisted_3))
          : (_openBlock(), _createElementBlock("img", _hoisted_4))
      ])
    ]),
    _: 1
  }, 8, ["onClose", "class"]))
}