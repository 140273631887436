
import { defineComponent } from "vue";
import store from "@/store";
import BasicButton from "@/components/Panels/BasicButton.vue";
import BigButton from "@/components/UI/BigButton.vue";
import { AudioUtils } from "@/utils";
import { MevahoMode } from "@/types/user";
import storeEvents from "@/store-events";

export default defineComponent({
  name: "play-game",
  components: { BigButton, BasicButton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    bonus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    controlDisabled(): boolean {
      return store.state.ui.controlDisabled;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    scoreboardMode(): MevahoMode {
      return store.state.userData.settings.mevaho_mode;
    },
    makeTicketDisabled(): boolean {
      return store.state.ui.makeTicketDisabled;
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
    isBonusGame(): boolean {
      return store.state.bonus.isOn;
    },
    disconnected(): boolean {
      return !store.state.connected;
    },
    promoIsOn(): boolean {
      return store.state.ui.promoIsOn;
    },
    tempPayout(): number {
      return store.state.ui.tempPayout;
    },
    currentPayout(): number {
      return store.state.currentTicket && store.state.currentTicket.payout
        ? store.state.currentTicket.payout
        : 0;
    },
    isBonusInBonusProcessing(): boolean {
      return store.state.bonus.isBonusInBonusProcessing;
    },
    isSkipDisabled(): boolean {
      return store.state.ui.isSkipDisabled;
    },
    hidden(): boolean {
      return (
        this.disabled ||
        this.isBetstop ||
        this.controlDisabled ||
        this.isDoublingOn ||
        this.makeTicketDisabled ||
        this.disconnected
      );
    },
  },
  methods: {
    makeTicket() {
      AudioUtils.playSound("button-press-sound", 0, 1);
      this.promoIsOn ? store.dispatch("makePromoTicket") : store.dispatch("makeTicket");
    },
    makeBonusTicket() {
      AudioUtils.playSound("button-press-sound", 0, 1);
      store.dispatch("makeBonusTicket");
      storeEvents.emit("stopAnimation");
    },
    skipAnimation() {
      storeEvents.emit("stopAnimation");
      store.dispatch("cancelTimerMakeBonusTicket");
      AudioUtils.stopSound("receipt-win-drop-sound");
      AudioUtils.stopSound("getting-free-tickets-short-khoja");
      AudioUtils.stopSound("getting-free-tickets-short");
      AudioUtils.stopSound("doubling-dropout-jazz");
      AudioUtils.stopSound("win-drop-sound");
      AudioUtils.stopSound("digit-short");
      AudioUtils.stopSound("fruit-middle");
      AudioUtils.stopSound("win-one-pamir-legends");
      AudioUtils.stopSound("win-three-pamir-legends");
      if (!this.isBonusInBonusProcessing) {
        store.dispatch("makeBonusTicket");
      } else {
        store.dispatch("cancelTimerMakeBonusInBonusTicket");
        store.commit("setIsSkipDisabled", true);
      }
      store.commit("setLastPayout", this.tempPayout + this.currentPayout);
    },
  },
});
