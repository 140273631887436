
import { defineComponent } from "vue";
import store from "@/store";
import BurgerModal from "@/components/Modals/BurgerModal.vue";

export default defineComponent({
  name: "modal-betstop",
  components: { BurgerModal },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile(): boolean {
      return store.state.uiVersion !== "desktop";
    },
  },
  methods: {
    closeModal() {
      store.dispatch("toggleModal", "betstop");
    },
  },
});
