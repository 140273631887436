
import { defineComponent } from "vue";
import storeEvents from "@/store-events";
import { BonusAnim } from "@/types/bonus";
import store from "@/store";

export default defineComponent({
  name: "beetle-magic",
  data() {
    return {
      melody: false,
      spin: false,
      beetle: false,
      startTimeout: undefined as number | undefined,
    };
  },
  props: {
    idx: {
      type: Number,
      required: true,
    },
  },
  beforeMount() {
    storeEvents.on("afterTicketUpdate", this.afterTicketUpdate);
    storeEvents.on("switchBonusAnim", this.switchAnim);
  },
  beforeUnmount() {
    storeEvents.off("afterTicketUpdate", this.afterTicketUpdate);
    storeEvents.off("switchBonusAnim", this.switchAnim);
  },
  computed: {
    beetleAnim(): BonusAnim | undefined {
      return store.state.bonus.animBonusArray[this.idx];
    },
  },
  methods: {
    afterTicketUpdate() {
      this.beetle = false;
      this.spin = false;
      this.melody = false;
    },
    switchAnim() {
      if (this.beetleAnim) {
        this.beetle = true;
        this.spin = true;
        this.melody = true;
      }
    },
  },
});
