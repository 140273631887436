import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47a81237"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "beetle-cell__container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BeetleMagic = _resolveComponent("BeetleMagic")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["beetle-cell", _ctx.styles])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.bonusAnim && _ctx.digit === 10)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            alt: "image",
            src: 
          require(`@/images/beetles/${_ctx.animation && _ctx.glow ? _ctx.bonusAnim : _ctx.bonusAnim + '-static'}.webp`)
        ,
            class: _normalizeClass(["beetle-cell__item", `--${_ctx.bonusAnim} --${_ctx.digit}`])
          }, null, 10, _hoisted_2))
        : (_ctx.isBonusInBonus && _ctx.digit === 0)
          ? (_openBlock(), _createBlock(_component_BeetleMagic, {
              key: 1,
              idx: _ctx.idx
            }, null, 8, ["idx"]))
          : (_openBlock(), _createElementBlock("img", {
              key: 2,
              alt: "image",
              src: 
          require(`@/images/beetles/${
            _ctx.animation && _ctx.glow ? 'anim-' + _ctx.digit : 'static-' + _ctx.digit
          }.webp`)
        ,
              class: _normalizeClass(["beetle-cell__item", `--${_ctx.digit}`])
            }, null, 10, _hoisted_3))
    ])
  ], 2))
}