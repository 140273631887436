
import { defineComponent } from "vue";

export default defineComponent({
  name: "sticker-info",
  props: {
    amount: Number,
    draw: Number,
  },
});
