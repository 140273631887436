import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StartScreen = _resolveComponent("StartScreen")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ModalList = _resolveComponent("ModalList")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-container", [_ctx.designType, _ctx.gameCss]])
  }, [
    (_ctx.showLoadingScreen)
      ? (_openBlock(), _createBlock(_component_StartScreen, {
          key: 0,
          progress: _ctx.progress
        }, null, 8, ["progress"]))
      : (_openBlock(), _createBlock(_component_router_view, { key: 1 })),
    _createVNode(_component_ModalList),
    _createElementVNode("div", {
      ref: "autoClickButton",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.playSound && _ctx.playSound(...args))),
      onTouchstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.playSound && _ctx.playSound(...args))),
      style: {"display":"none"}
    }, null, 544)
  ], 2))
}