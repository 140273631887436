import {
  IAPI,
  IAPIEventMap,
  TransportAPI,
  TransportDisconnectCode,
  TransportState,
} from "./interface";
import { EventEmitter } from "tsee";
import { AuthSimple, AuthSimpleResult, ChangeBalance, UserData } from "@/types/user";
import { MessagePacket, MessagePacketTypes } from "@/types/packets";
import { BackSimulation } from "./mock-ws";
import { WSBackend } from "./ws-backend";
import { v4 as uuidv4 } from "uuid";
import { TransportAPIRouter } from "./wip-fake-api";
import { SettingsState } from "@/types/settings";
import { TicketRequest, TicketResponse, UpdateTicket } from "@/types/tickets";
import { DrawHistoryRequest, DrawHistoryResponse } from "@/types/draws";
import {
  DoublingGame,
  PlayDoublingGameRequest,
  PlayDoublingGameResponse,
  GetLastDoublingGameRequest,
  GetLastDoublingGameResponse,
} from "@/types/doubling";
import { SessionInfoUpdate } from "@/types/session";
import { MakeBonusTicketRequest, MakeBonusTicketResponse } from "@/types/bonus";
import { PromoState } from "@/types/promo";

type DeferPromise<T> = Promise<T> & { resolve: Function; reject: Function };

type PromiseQueue = {
  [uuid in string]: DeferPromise<any>;
};

const WATCHDOG_INTERVAL = 1 * 1000;
// time to wait since last packet to trigger ping-pong
const PING_TRIGGER_INTERVAL = 10 * 1000;
// time to wait for ping response
const PONG_WAIT_INTERVAL = 10 * 1000;

// exclude packet types from rid check in packet processing, because backend can send different packets with same rid
const EXCLUDE_FROM_RID_CHECK: MessagePacketTypes[] = [];

export class API extends EventEmitter<IAPIEventMap> implements IAPI {
  private transport: TransportAPI;
  private queue: PromiseQueue = {};
  // timestamp of last incoming packet
  private lastIncomingPacketTime: number = Date.now();
  private lastPingTime?: number;
  private watchDogTimeout?: number;

  constructor(transport: TransportAPI) {
    super();

    this.transport = transport;
    this.transport.on("message", (packet: MessagePacket) => {
      console.debug("Incoming packet", packet);
      this._processPacket(packet);
    });
    this.transport.once("connect", () => {
      console.debug("Transport ready");
      this.emit("ready");
      // start watchdog after connection
      this.watchDog();
    });
    this.transport.on("disconnect", (code: number) => {
      console.debug("Transport disconnected");
      this.emit("disconnect", code);
    });
    this.transport.once("offline", () => {
      console.debug("Transport offline");
      this.emit("offline");
    });
  }

  protected watchDog(): void {
    if (this.watchDogTimeout) {
      window.clearTimeout(this.watchDogTimeout);
    }

    // console.debug("watchDog", "state is", this.transport.getState(), this.lastPingTime);

    // dont do anything if not connected, stop watchdog
    if (this.transport.getState() !== TransportState.CONNECTED) {
      return;
    }

    this.checkConnection();

    // restart watchdog
    this.watchDogTimeout = window.setTimeout(() => {
      this.watchDog();
    }, WATCHDOG_INTERVAL);
  }

  protected checkConnection(): void {
    const now = Date.now();
    // check if ping already sent
    if (this.lastPingTime) {
      // disconnect transport if no response for ping within PING_TRIGGER_INTERVAL seconds
      if (now - this.lastPingTime >= PONG_WAIT_INTERVAL) {
        console.warn("No hearbeat detected, force disconnect :(");
        this.transport.disconnect(TransportDisconnectCode.NO_HEARTBEAT);
        this.lastPingTime = undefined;
      }
    } else {
      // if last packet was ages ago sends ping packet
      if (now - this.lastIncomingPacketTime >= PING_TRIGGER_INTERVAL) {
        // send new ping
        // console.debug("sending ping");
        this.ping();
        this.lastPingTime = now;
      }
    }
  }

  protected async sendQueuedRequest<T>(
    type: MessagePacketTypes,
    payload: object = {},
    uuid: boolean = true
  ): Promise<T> {
    const packet = this.makePacket(type, payload, uuid);
    const promise = (this.queue[packet.rid!] = this.defer<T>());
    this.transport.send(packet);
    return promise;
  }

  async getUserData(): Promise<UserData> {
    return this.sendQueuedRequest(MessagePacketTypes.GET_USER_DATA);
  }

  async updateUserData(data: Partial<UserData>): Promise<string> {
    return this.sendQueuedRequest(MessagePacketTypes.UPDATE_USER_DATA, data);
  }

  async restoreAuthorization(payload: object): Promise<AuthSimpleResult> {
    return this.sendQueuedRequest(MessagePacketTypes.RESTORE_AUTH, payload);
  }

  async simpleAuth(payload: AuthSimple): Promise<AuthSimpleResult> {
    return this.sendQueuedRequest(MessagePacketTypes.SIMPLE_AUTH, payload);
  }

  async getDrawHistory(payload: DrawHistoryRequest): Promise<DrawHistoryResponse> {
    return this.sendQueuedRequest(MessagePacketTypes.DRAW_HISTORY, payload);
  }

  ping(): void {
    this.transport.send(this.makePacket(MessagePacketTypes.PING, {}, true));
  }

  async getSettings(): Promise<SettingsState> {
    return this.sendQueuedRequest(MessagePacketTypes.GET_SETTINGS);
  }

  async makeTicket(payload: TicketRequest): Promise<TicketResponse> {
    return this.sendQueuedRequest(MessagePacketTypes.MAKE_TICKET, payload);
  }

  async doublingGame(payload: DoublingGame): Promise<{ result: "OK" }> {
    return this.sendQueuedRequest(MessagePacketTypes.DOUBLING_GAME, payload);
  }

  async playDoublingGame(payload: PlayDoublingGameRequest): Promise<PlayDoublingGameResponse> {
    return this.sendQueuedRequest(MessagePacketTypes.PLAY_DOUBLING_GAME, payload);
  }

  async getLastDoublingGames(
    payload: GetLastDoublingGameRequest
  ): Promise<GetLastDoublingGameResponse> {
    return this.sendQueuedRequest(MessagePacketTypes.GET_LAST_DOUBLING_GAMES, payload);
  }

  async makeBonusTicket(payload: MakeBonusTicketRequest): Promise<MakeBonusTicketResponse> {
    return this.sendQueuedRequest(MessagePacketTypes.MAKE_BONUS_TICKET, payload);
  }

  async getPromo(): Promise<PromoState> {
    return this.sendQueuedRequest(MessagePacketTypes.GET_PROMO);
  }

  public reconnect(): Promise<void> {
    this.lastPingTime = undefined;
    return new Promise((resolve, reject) => {
      this.transport.once("connect", () => {
        resolve();
        // update last packet time to prevent immediate ping
        this.lastIncomingPacketTime = Date.now();
        this.watchDog();
      });
      this.transport.once("offline", () => {
        reject();
      });

      this.transport.reconnect();
    });
  }

  public disconnect(): void {
    this.transport.disconnect(TransportDisconnectCode.CLOSE_API);
  }

  public disconnectFixIos(): void {
    this.transport.disconnectFixIos(TransportDisconnectCode.NO_HEARTBEAT);
  }

  protected makePacket(
    type: MessagePacketTypes,
    payload: object,
    uuid: boolean = false
  ): MessagePacket {
    return { event: type, data: payload, rid: uuid ? uuidv4() : undefined };
  }

  _processPacket(packet: MessagePacket) {
    // update last incoming packet timestamp and drop last ping time
    this.lastIncomingPacketTime = Date.now();
    this.lastPingTime = undefined;

    // if (packet.type === MessagePacketTypes.BALANCE_UPDATE) return;

    // console.debug('Processing packet: ', packet.type);
    // process packets with uuid and resolve wating promises (with types not in EXCLUDE_FROM_RID_CHECK)
    if (
      packet.rid &&
      this.queue.hasOwnProperty(packet.rid) &&
      !EXCLUDE_FROM_RID_CHECK.includes(packet.event)
    ) {
      const promise = this.queue[packet.rid];
      packet.error ? promise.reject(packet.error) : promise.resolve(packet.data);
      delete this.queue[packet.rid];
    } else {
      // just emit events
      switch (packet.event) {
        case MessagePacketTypes.UPDATE_TICKET:
          this.emit("updateTicket", packet.data as UpdateTicket);
          break;
        case MessagePacketTypes.SESSION_INFO:
          this.emit("sessionInfo", packet.data as SessionInfoUpdate);
          break;
        case MessagePacketTypes.BALANCE_CHANGED:
          this.emit("changeBalance", packet.data as ChangeBalance);
          break;
        case MessagePacketTypes.CLOSE_SESSION:
          this.emit("closeSession");
          break;
        default:
          break;
      }
    }
  }

  protected defer<T>(): DeferPromise<T> {
    let res: Function, rej: Function;
    const promise = new Promise<T>((resolve, reject) => {
      res = resolve;
      rej = reject;
    }) as DeferPromise<T>;
    promise.resolve = res!;
    promise.reject = rej!;
    return promise;
  }
}

// can be passed into app from env vars, @see vue.config.js
declare const API_URL: string;

export function getBackURL(): string {
  if (API_URL !== undefined && !!API_URL.trim()) {
    return API_URL;
  } else {
    return "wss://api.lotogame.dev.superlive.bet/ws";
  }
}

export function makeApi() {
  return new API(new WSBackend(getBackURL()));
}

export function makeFullFakeApi() {
  const mock = new BackSimulation();
  const api = new API(new TransportAPIRouter(mock, mock));
  console.warn("Use API mock");
  return api;
}

export function isLocalApi() {
  return location.href.includes("localapi");
}

export const api: IAPI = isLocalApi() ? makeFullFakeApi() : makeApi();
