import { RouteRecordRaw } from "vue-router";
import Main from "@/layouts/Main.vue";
import Doubling from "@/layouts/Doubling.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "market",
    component: Main,
  },
  {
    path: "/doubling",
    name: "doubling",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ "../layouts/Doubling.vue"),
    component: Doubling,
  },
  // {
  //   path: '*',
  //   name: '404',
  //   component: HomeView,
  // },
];

export default routes;
