
import { defineComponent } from "vue";
import CircleButton from "@/components/Panels/CircleButton.vue";
import store from "@/store";
import { TicketCombination } from "@/types/tickets";
import { AudioUtils } from "@/utils";

export default defineComponent({
  name: "combination-control",
  components: { CircleButton },
  props: {},
  computed: {
    newCombinationNumber(): number {
      return store.getters.newCombinationNumber;
    },
    combinations(): TicketCombination[] {
      return store.getters.ticketCombinations;
    },
    currentCombination(): TicketCombination | undefined {
      return store.state.createTicket.combination;
    },
    controlDisabled(): boolean {
      return store.state.ui.controlDisabled;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    makeTicketDisabled(): boolean {
      return store.state.ui.makeTicketDisabled;
    },
    disconnected(): boolean {
      return !store.state.connected;
    },
    promoIsOn(): boolean {
      return store.state.ui.promoIsOn;
    },
  },
  methods: {
    combinationSelectionPlus() {
      AudioUtils.playSound("button-press-sound", 0, 1);
      const list = this.combinations;
      let idx = list.findIndex((el) => el === this.currentCombination);
      if (idx === -1 || idx === list.length - 1) {
        store.dispatch("setNewCombination", list[0]);
      } else {
        store.dispatch("setNewCombination", list[idx + 1]);
      }
    },
    combinationSelectionMinus() {
      AudioUtils.playSound("button-press-sound", 0, 1);
      const list = this.combinations;
      let idx = list.findIndex((el) => el === this.currentCombination);
      if (idx === -1 || idx === 0) {
        store.dispatch("setNewCombination", list[list.length - 1]);
      } else {
        store.dispatch("setNewCombination", list[idx - 1]);
      }
    },
  },
});
