
import { defineComponent } from "vue";

export default defineComponent({
  name: "fruit-cell",
  props: {
    digit: Number,
    animation: {
      type: Boolean,
      default: false,
    },
    glow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles(): object {
      return {
        "--glow": this.animation && this.glow,
        "--opacity": this.animation && !this.glow,
      };
    },
  },
});
