import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33e25f23"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "last-boxes"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.updateLastDoublingGames.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.updateLastDoublingGames, (box, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "last-boxes__container",
            key: box.id
          }, [
            _createElementVNode("img", {
              src: _ctx.currentImage(box, index),
              class: _normalizeClass({
          'last-boxes-row__rightBox':
            (box.sign === 'right' && box.status === 'won') ||
            (box.sign === 'left' && box.status === 'lost'),
          'last-boxes-row__leftBox':
            (box.sign === 'left' && box.status === 'won') ||
            (box.sign === 'right' && box.status === 'lost'),
          'last-boxes-row__firstBox': index === 0,
        })
            }, null, 10, _hoisted_2)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}