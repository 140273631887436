
import { defineComponent } from "vue";
import store from "@/store";
import { TicketCombination } from "@/types/tickets";

export default defineComponent({
  name: "highlighting-lines",
  data() {
    return {
      show: false,
    };
  },
  props: {
    small: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    imageCss() {
      return {
        "--combi-5": store.state.createTicket.combination === "combi-5",
        "--small": this.small,
      };
    },
  },
  watch: {
    "$store.state.createTicket.combination": function (n: TicketCombination, o: TicketCombination) {
      if (n !== o) {
        this.show = true;
      }
    },
  },
});
