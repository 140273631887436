import { EventEmitter } from "tsee";
import { UpdateTicket } from "@/types/tickets";
import { DrawItem } from "@/types/draws";

const storeEvents = new EventEmitter<{
  afterTicketUpdate(ticket: UpdateTicket, stopAnim?: boolean): void;
  afterErrorTicketUpdate(data: DrawItem): void;
  isPlayDoublingWon(value: boolean): void;
  switchBonusAnim(): void;
  nextPlayGame(): void;
  lastPlayedGame(): void;
  createTicket(): void;
  stopAnimation(): void;
  routeChanged(): void;
}>();

storeEvents.setMaxListeners(50);

export default storeEvents;
