import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["simple-button", {
      '--disabled': _ctx.disabled,
      '--buyIn': _ctx.buyIn,
      '--blink': !_ctx.isBonusGame && _ctx.buyIn && !_ctx.isHidden,
    }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["simple-button__content", { '--buyIn': _ctx.buyIn }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 2))
}