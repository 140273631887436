
import { defineComponent } from "vue";
import CurrencyIcon from "@/components/CurrencyIcon.vue";

export default defineComponent({
  name: "input-control",
  components: { CurrencyIcon },
  props: {
    title: String,
    value: Number,
    reverse: {
      type: Boolean,
      default: false,
    },
    showCurrency: {
      type: Boolean,
      default: false,
    },
    center: {
      default: false,
      type: Boolean,
    },
    glow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showValue() {
      return this.value ? this.value / 100 : 0;
    },
  },
});
