
import { defineComponent } from "vue";
import { api } from "@/api";
import store from "@/store";

export default defineComponent({
  name: "big-button",
  props: {
    text: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {},
});
