
import { defineComponent } from "vue";
import store from "@/store";
import SimpleInfo from "@/components/SimpleInfo.vue";
import MenuTop from "@/components/MenuTop.vue";
import { GameId } from "@/types/user";

export default defineComponent({
  name: "main-header",
  components: { MenuTop, SimpleInfo },
  computed: {
    isWidescreen(): boolean {
      return store.state.userData?.settings?.widescreen === "on";
    },
    gameId(): GameId {
      return store.state.currentGameId;
    },
  },
});
