import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:checked', !_ctx.checked))),
    class: "switcher-checkbox"
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["switcher-checkbox__label", { '--active': _ctx.checked }])
    }, _toDisplayString(_ctx.label), 3),
    _createElementVNode("div", {
      class: _normalizeClass(["switcher-checkbox__button", { '--active': _ctx.checked }])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["switcher-checkbox__lever", { '--active': _ctx.checked }])
      }, null, 2)
    ], 2)
  ]))
}