
import { defineComponent, PropType } from "vue";
import { TicketCombination } from "@/types/tickets";
import { TICKET_COMBINATIONS } from "@/constants";

export default defineComponent({
  name: "combination-item",
  data() {
    return {};
  },
  props: {
    glow: Boolean,
    value: {
      required: true,
      type: String as PropType<TicketCombination>,
    },
  },
  computed: {
    combination(): { array: string[]; number: number } {
      const result = TICKET_COMBINATIONS[this.value];
      if (!result) console.error("Пришла неподдерживаемая комбинация!");
      return result ?? { array: [], number: 0 };
    },
    orCombinationText() {
      return this.combination.array.length > 1 ? this.$t("orCombination") : "";
    },
  },
  methods: {},
});
