import { api } from "@/api";
import { TypedStore } from "@/store";
import { UpdateTicket } from "@/types/tickets";
import { SessionInfoUpdate } from "@/types/session";

/**
 * Plugin to propagate api updates to vuex store mutations
 * @param store Store
 *
 * По идее оно слушает все события api и меняет state, а компоненты смотрят только state и дергают commit/dispatch
 */
const storePlugin = (store: TypedStore) => {
  api.on("offline", () => {
    store.dispatch("offlineHappens");
  });
  api.on("sessionInfo", (data: SessionInfoUpdate) => {
    store.dispatch("updateSessionInfo", data);
  });
  api.on("disconnect", (code: number) => {
    store.dispatch("disconnectHappens", code);
  });
  api.on("updateTicket", (ticket: UpdateTicket) => {
    if (!store.state.bonus.isOn) {
      store.dispatch("setCurrentTicket", ticket);
    } else {
      store.dispatch("setBonusTicket", ticket);
    }
  });
  api.on("changeBalance", (data) => {
    store.commit("updateBalanceUserData", data.balance);
    if (store.state.ui.autoMakeTicketBlocked) {
      store.commit("setAutoMakeTicketBlocked", false);
      window.setTimeout(() => {
        store.dispatch("autoMakeTicket", { delay: false, force: true });
      }, 500);
    }
  });
  api.on("closeSession", () => {
    store.dispatch("closeSession");
  });
};

export default storePlugin;
