
import { defineComponent } from "vue";

export default defineComponent({
  name: "countdown-timer",
  data() {
    return {
      // clearInterval: undefined as number | undefined,
      // seconds: 0 as number | string,
      // minutes: 0 as number | string,
    };
  },
  props: {
    value: {
      required: true,
      type: Number,
      default: 0,
    },
    simple: Boolean,
  },
  computed: {
    formatMin() {
      const minutes = Math.floor(this.value / 60);
      return minutes < 10 ? "0" + minutes : minutes;
    },
    formatSec() {
      const seconds = this.value - Math.floor(this.value / 60) * 60;
      return seconds < 10 ? "0" + seconds : seconds;
    },
  },
  methods: {
    onFinish() {
      this.$emit("onFinish");
    },
    // calcTime(countDown: number) {
    //   const now = Date.now();
    //   const distance = countDown - now;
    //
    //   if (distance >= 0) {
    //     const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //     minutes < 10 ? (this.minutes = "0" + minutes) : (this.minutes = minutes);
    //     const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    //     seconds < 10 ? (this.seconds = "0" + seconds) : (this.seconds = seconds);
    //   } else {
    //     window.clearInterval(this.clearInterval);
    //     this.onFinish();
    //   }
    // },
    // start() {
    //   const countDown = Date.now() + this.value * 1010;
    //   this.calcTime(countDown);
    //
    //   this.clearInterval = window.setInterval(() => {
    //     this.calcTime(countDown);
    //   }, 1000);
    // },
  },
  watch: {
    value(n) {
      if (n === 0) {
        this.onFinish();
      }
    },
  },
  // created() {
  //   this.start();
  // },
  // beforeUnmount() {
  //   window.clearInterval(this.clearInterval);
  // },
});
