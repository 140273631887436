import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6542263a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-control__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["button-control", { '--glow': _ctx.glow, '--disabled': _ctx.disabled }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["button-control__img", _ctx.cssColor])
    }, null, 2),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.formatMoney), 1)
  ], 2))
}