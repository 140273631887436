import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fba2419"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "doubling-desktop-khoja" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "doubling-desktop-khoja__group" }
const _hoisted_6 = { class: "doubling-desktop-khoja__group-frame" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "doubling-desktop-khoja__group-nominals" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "doubling-desktop-khoja__group-last-boxes" }
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoublingHeader = _resolveComponent("DoublingHeader")!
  const _component_InputFlame = _resolveComponent("InputFlame")!
  const _component_LastBoxes = _resolveComponent("LastBoxes")!
  const _component_DoublingBoxes = _resolveComponent("DoublingBoxes")!
  const _component_ControlPanel = _resolveComponent("ControlPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "doubling-desktop-khoja__top",
      src: require('@/images/backgrounds/doubling-khoja-up-grape.webp'),
      alt: "top grape"
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      class: "doubling-desktop-khoja__left",
      src: require('@/images/backgrounds/doubling-khoja-left-grape.webp'),
      alt: "left grape"
    }, null, 8, _hoisted_3),
    _createElementVNode("img", {
      class: "doubling-desktop-khoja__right",
      src: require('@/images/backgrounds/doubling-khoja-right-grape.webp'),
      alt: "right grape"
    }, null, 8, _hoisted_4),
    _createVNode(_component_DoublingHeader),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          class: "doubling-desktop-khoja__frame",
          src: require('@/images/ui/frame-khoja.png'),
          alt: "frame"
        }, null, 8, _hoisted_7)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expectedWinnings, (item, idx) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (_ctx.fireproof && idx === _ctx.fireproof)
              ? (_openBlock(), _createBlock(_component_InputFlame, {
                  key: 0,
                  value: item,
                  blink: idx === _ctx.stepCount + 1,
                  animation: _ctx.stepCount === _ctx.fireproof
                }, null, 8, ["value", "blink", "animation"]))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass({
              'doubling-desktop-khoja__group-nominal-active': idx === _ctx.stepCount,
              'doubling-desktop-khoja__group-nominal': idx !== _ctx.stepCount,
            })
                }, _toDisplayString((item / 100).toFixed(2)), 3))
          ], 64))
        }), 256))
      ]),
      _createElementVNode("img", {
        class: "doubling-desktop-khoja__left-bottom",
        src: require('@/images/backgrounds/doubling-khoja-bottomLeft-grape.webp'),
        alt: "left-bottom grape"
      }, null, 8, _hoisted_9)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("img", {
        class: "doubling-desktop-khoja__frame",
        src: require('@/images/ui/frame-khoja.png'),
        alt: "frame"
      }, null, 8, _hoisted_11),
      _createVNode(_component_LastBoxes),
      _createElementVNode("img", {
        class: "doubling-desktop-khoja__right-bottom",
        src: require('@/images/backgrounds/doubling-khoja-bottomRight-grape.webp'),
        alt: "right-bottom grape"
      }, null, 8, _hoisted_12)
    ]),
    _createVNode(_component_DoublingBoxes),
    _createVNode(_component_ControlPanel, {
      class: "doubling-desktop-khoja__panel",
      compact: ""
    })
  ]))
}