
import { defineComponent, PropType } from "vue";
import store from "@/store";
import { TicketCombination } from "@/types/tickets";
import MobileButton from "@/components/UI/MobileButton.vue";
import { AudioUtils } from "@/utils";

export default defineComponent({
  name: "combination-button",
  components: { MobileButton },
  props: {},
  computed: {
    controlDisabled(): boolean {
      return store.state.ui.controlDisabled;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    makeTicketDisabled(): boolean {
      return store.state.ui.makeTicketDisabled;
    },
    combinations(): TicketCombination[] {
      return store.getters.ticketCombinations;
    },
    currentCombination(): TicketCombination | undefined {
      return store.state.createTicket.combination;
    },
    isMobile(): boolean {
      return store.state.uiVersion !== "desktop";
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    disconnected(): boolean {
      return !store.state.connected;
    },
    promoIsOn(): boolean {
      return store.state.ui.promoIsOn;
    },
    isPamir(): boolean {
      return store.state.currentGameId === "loto-pamir-legends";
    },
    buttonColor(): string {
      return this.isPamir ? "iceblue" : "red";
    },
  },
  methods: {
    combinationSelection() {
      AudioUtils.playSound("button-press-sound", 0, 1);
      const list = this.combinations;
      let idx = list.findIndex((el) => el === this.currentCombination);
      if (idx === -1 || idx === list.length - 1) {
        store.dispatch("setNewCombination", list[0]);
      } else {
        store.dispatch("setNewCombination", list[idx + 1]);
      }
    },
  },
});
