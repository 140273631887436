
import { defineComponent } from "vue";
import InfoModal from "@/components/Modals/InfoModal.vue";
import PDF from "pdf-vue3";
import store from "@/store";
import { GameId } from "@/types/user";
import i18n from "@/i18n";

export default defineComponent({
  name: "modal-rules-info",
  components: { InfoModal, PDF },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    gameId(): GameId {
      return store.state.currentGameId;
    },
    currentLang(): string {
      return i18n.global.locale.value;
    },
    currentRules(): string {
      return `/rules/${this.currentLang}/${this.gameId}.pdf`;
    },
  },
  methods: {
    closeModal() {
      store.dispatch("toggleModal", "rules");
    },
  },
});
