
import store from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  name: "simple-button",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    buyIn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isBonusGame(): boolean {
      return store.state.bonus.isOn;
    },
    isDoublingDisabled(): boolean {
      return store.state.ui.isDoublingDisabled;
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    isFruitDoubling(): boolean {
      return store.state.userData.settings.mevaho_mode === "fruit" && this.isDoublingOn;
    },
    disconnected(): boolean {
      return !store.state.connected;
    },
    isHidden(): boolean {
      return (
        this.disabled ||
        this.isDoublingDisabled ||
        this.isBetstop ||
        this.isFruitDoubling ||
        this.disconnected
      );
    },
  },
  methods: {},
});
