
import { defineComponent } from "vue";
import store from "@/store";
import BurgerModal from "@/components/Modals/BurgerModal.vue";
import MyButton from "@/components/UI/MyButton.vue";
import CountdownTimer from "@/components/CountdownTimer.vue";
import { AUTO_DOUBLING_CLOSE_TIMER } from "@/constants";
import CurrencyIcon from "@/components/CurrencyIcon.vue";
import { AudioUtils } from "@/utils";
import router from "@/router";

export default defineComponent({
  name: "modal-autodoubling",
  components: { CountdownTimer, MyButton, BurgerModal, CurrencyIcon },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closeTimer: undefined as number | undefined,
      confirmDelay: AUTO_DOUBLING_CLOSE_TIMER / 1000,
    };
  },
  computed: {
    lastPayoutCalc(): number {
      return store.state.ui.lastPayout / 50;
    },
    autoGame(): boolean {
      return store.state.ui.autoGame;
    },
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
    isFruitMode(): boolean {
      return store.state.userData.settings.mevaho_mode === "fruit";
    },
    isMaintenance(): boolean {
      return store.state.session.maintenance;
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
  },
  methods: {
    closeModal() {
      store.dispatch("toggleModal", "autodoubling");
      store.dispatch("closeDoublingGame");
      store.commit("setMakeTicketDisabled", store.state.ui.autoGame);
      if (this.autoGame) store.dispatch("makeTicket");
    },
    apply() {
      store.dispatch("toggleModal", "autodoubling");
      store.commit("setAutoGame", false);
      AudioUtils.playSound("stop-autoplay", 0, 1);
      store.commit("setMakeTicketDisabled", false);
      store.commit("setIsTakePayout", true);
      if (this.isFruitMode) {
        router.push("/doubling");
        store.dispatch("startDoublingTimer");
      } else {
        store.dispatch("applyDoublingGame");
      }
    },
  },
  watch: {
    open(n, o) {
      if (n) {
        this.closeTimer = window.setInterval(() => {
          if (this.confirmDelay === 0) {
            clearInterval(this.closeTimer);
            return;
          }
          this.confirmDelay -= 1;
        }, 1000);
      } else {
        this.confirmDelay = AUTO_DOUBLING_CLOSE_TIMER / 1000;
        clearInterval(this.closeTimer);
      }
    },
  },
});
