
import { defineComponent, PropType } from "vue";
import store from "@/store";

export default defineComponent({
  name: "circle-button",
  props: {
    type: String as PropType<"plus" | "minus">,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    promoIsOn(): boolean {
      return store.state.ui.promoIsOn;
    },
  },
});
