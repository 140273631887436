import { EventEmitter, IEventEmitter } from "tsee";
import { MessagePacket } from "@/types/packets";
import {
  TransportEventMap,
  TransportAPI,
  TransportState,
  TransportDisconnectCode,
} from "./interface";

export class WSBackend extends EventEmitter<TransportEventMap> implements TransportAPI {
  private ws!: WebSocket;
  private falloffAttempts = 2;
  private maxReconnectAttempts: number = 60;
  private reconnectAttempts: number = 0;
  private falloffReconnectDelay: number = 2000;
  private constantReconnectDelay = 20 * 1000;
  private state: TransportState;

  constructor(public url: string) {
    super();
    this.state = TransportState.DISCONNECTED;
    this.initialize(url);
  }

  getState(): TransportState {
    return this.state;
  }

  protected initialize(url: string) {
    this.ws = new WebSocket(url);
    this.ws.onopen = (e) => {
      this.onOpen(e);
    };
    this.ws.onclose = (e) => {
      this.onClose(e);
    };
    this.ws.onerror = (e) => {
      this.onErorr(e);
    };
    this.ws.onmessage = (e) => {
      this.onMessage(e);
    };
  }

  public disconnect(code: TransportDisconnectCode): void {
    // close with app code
    console.debug(`Close transport code ${code}`);
    this.ws.close(code, "app forced disconnect");
    this.state = TransportState.DISCONNECTED;
  }

  public disconnectFixIos(code: TransportDisconnectCode): void {
    console.debug(`Close transport code by onoffline ${code}`);
    this.onClose({ code } as CloseEvent);
    this.state = TransportState.DISCONNECTED;
  }

  public send(packet: MessagePacket): void {
    if (this.ws.readyState === WebSocket.OPEN) {
      // include rid into data for backend
      const o = {
        event: packet.event,
        data: {
          ...packet.data,
          rid: packet.rid,
        },
      };
      this.ws.send(JSON.stringify(o));
    } else {
      location.reload();
      throw new Error("WS is not connected");
    }
  }

  public reconnect(): void {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.state = TransportState.RECONNECTING;
      const delay =
        this.reconnectAttempts > this.falloffAttempts
          ? this.constantReconnectDelay
          : Math.pow(this.reconnectAttempts + 1, 2) * this.falloffReconnectDelay;
      console.debug(`Reconnecting after ${delay / 1000} sec`);
      setTimeout(() => {
        this.reconnectAttempts++;
        this.initialize(this.url);
      }, delay);
    } else {
      this.state = TransportState.OFFLINE;
      console.warn("Max reconnect attempts reached");
      this.emit("offline");
    }
  }

  protected onOpen(e: any) {
    this.reconnectAttempts = 0;
    this.state = TransportState.CONNECTED;
    this.emit("connect");
  }

  protected onClose(e: CloseEvent) {
    if (e.code === TransportDisconnectCode.CLOSE_API) return;
    if (this.state === TransportState.RECONNECTING) {
      this.reconnect();
    } else {
      this.state = TransportState.DISCONNECTED;
      this.emit("disconnect", e.code);
    }
  }

  protected onErorr(e: any) {
    if (this.ws.readyState !== WebSocket.OPEN) {
      this.ws.close();
    }
  }

  protected onMessage(e: any) {
    try {
      const p = JSON.parse(e.data);
      this.emit("message", p);
    } catch (error) {
      console.log(error, e);
    }
  }
}
