import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../images/icons/rbon.svg'


const _withScopeId = n => (_pushScopeId("data-v-2d5b0336"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "currency_svg",
  src: _imports_0,
  alt: "Rbon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["currency-icon", {
      '--rbon': _ctx.rbon,
    }])
  }, [
    (_ctx.rbon)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(["currency", _ctx.underlineCss])
        }, _toDisplayString(_ctx.currencyIcon), 3))
  ], 2))
}