import State from "./state";
import { ACtx } from "@/store";
import { api } from "@/api";
import { BonusAnim, MakeBonusTicketRequest } from "@/types/bonus";
import storeEvents from "@/store-events";
import {
  BONUS_IN_BONUS_ANIM_DELAY,
  MAKE_TICKET_DELAY,
  MAKE_TICKET_DELAY_DIGIT,
  TYPICAL_ANIMATION_TIME_DIGIT,
} from "@/constants";
import { AudioUtils, shuffleArray } from "@/utils";

export type BonusState = {
  isOn: boolean;
  countGame: number;
  numGames: number;
  animDoublingBonus: undefined | BonusAnim;
  animDoublingBonusTime: number;
  animBonusArray: (BonusAnim | undefined)[];
  isBonusInBonusProcessing: boolean;
};

export const state: BonusState = {
  isOn: false,
  countGame: 0,
  numGames: 10,
  animDoublingBonus: undefined,
  animDoublingBonusTime: 0,
  animBonusArray: new Array(15),
  isBonusInBonusProcessing: false,
};

let bonusTimer: undefined | number = undefined;
let timerMakeBonusInBonusTicket: number | undefined = undefined;
let timerSwitcherBonusAnim: number | undefined = undefined;

export const mutations = {
  setIsBonusOn(state: State, value: boolean) {
    state.bonus.isOn = value;
  },
  addStepToBonusCount(state: State) {
    state.bonus.countGame += 1;
  },
  setBonusCount(state: State, value: number) {
    state.bonus.countGame = value;
  },
  clearBonusCount(state: State) {
    state.bonus.countGame = 0;
  },
  setRandomBonusAnim(state: State, delay: number) {
    window.clearTimeout(bonusTimer);
    const random = Math.random();
    if (random < 0.2) {
      state.bonus.animDoublingBonusTime = 5500;
    } else if (random < 0.4) {
      state.bonus.animDoublingBonusTime = 8000;
    } else if (random < 0.6) {
      state.bonus.animDoublingBonusTime = 6000;
    } else if (random < 0.8) {
      state.bonus.animDoublingBonusTime = 6000;
    } else {
      state.bonus.animDoublingBonusTime = 4000;
    }
    bonusTimer = window.setTimeout(() => {
      if (random < 0.2) {
        state.bonus.animDoublingBonus = "viola";
      } else if (random < 0.4) {
        state.bonus.animDoublingBonus = "sax";
      } else if (random < 0.6) {
        state.bonus.animDoublingBonus = "guitar";
      } else if (random < 0.8) {
        state.bonus.animDoublingBonus = "sing";
      } else {
        state.bonus.animDoublingBonus = "drum";
      }
    }, delay);
  },
  resetRandomBonusAnim(state: State) {
    state.bonus.animDoublingBonus = undefined;
    state.bonus.animDoublingBonusTime = 0;
  },
  setNumGames(state: State, value: number) {
    state.bonus.numGames = value;
  },
  /* addNumGames(state: State, value: number) {
    state.bonus.numGames += value;
  },*/
  setIsBonusInBonusProcessing(state: State, value: boolean) {
    state.bonus.isBonusInBonusProcessing = value;
  },
  rollAnimBonusArray(state: State, array: number[][]) {
    const idxsArray = array[0];
    const initialArray = shuffleArray<BonusAnim[]>(["viola", "sax", "guitar", "sing", "drum"]);
    const resultArray: (BonusAnim | undefined)[] = new Array(15);
    idxsArray.forEach((item, index) => {
      resultArray.splice(item, 1, initialArray[index]);
    });
    state.bonus.animBonusArray = resultArray;
  },
};

export const actions = {
  async makeBonusTicket({ state, commit, dispatch }: ACtx) {
    commit("setIsSkipDisabled", true);
    AudioUtils.stopSound("getting-free-tickets");
    AudioUtils.stopSound("getting-free-tickets-short-khoja");
    commit("setShowBonusModal", { show: false, start: true });
    commit("setShowAnimation", "close");
    const payload: MakeBonusTicketRequest = {
      ticketID: state.lastTicket && state.lastTicket.ticket_id ? state.lastTicket.ticket_id : 0,
    };

    if (
      state.userData.settings.mevaho_mode === "fruit" &&
      state.currentGameId === "loto-gambusaki-9"
    ) {
      AudioUtils.stopSound("slot-carpet-gambusaki");
      AudioUtils.playSound("slot-carpet-gambusaki", 0, 4);
    } else if (
      state.userData.settings.mevaho_mode === "fruit" &&
      state.currentGameId === "loto-gambusaki-gold-9"
    ) {
      AudioUtils.stopSound("slot-carpet-gambusaki-gold");
      AudioUtils.playSound("slot-carpet-gambusaki-gold", 0, 4);
    } else if (
      state.userData.settings.mevaho_mode === "fruit" &&
      state.currentGameId === "loto-khoja"
    ) {
      AudioUtils.stopSound("slot-carpet-khoja");
      AudioUtils.playSound("slot-carpet-khoja", 0, 4);
    } else if (
      state.userData.settings.mevaho_mode === "fruit" &&
      state.currentGameId === "loto-pearl"
    ) {
      AudioUtils.stopSound("bonus-slot-carpet-pearl");
      AudioUtils.playSound("bonus-slot-carpet-pearl", 0, 4);
    } else if (
      state.userData.settings.mevaho_mode === "fruit" &&
      state.currentGameId === "loto-astrologer"
    ) {
      AudioUtils.stopSound("bonus-slot-carpet-astrologer");
      AudioUtils.playSound("bonus-slot-carpet-astrologer", 0, 4);
    } else if (
      state.userData.settings.mevaho_mode === "fruit" &&
      state.currentGameId === "loto-kissaho"
    ) {
      AudioUtils.stopSound("slot-carpet-kissaho");
      AudioUtils.playSound("slot-carpet-kissaho", 0, 4);
    } else if (
      state.userData.settings.mevaho_mode === "fruit" &&
      state.currentGameId === "loto-silk-way"
    ) {
      AudioUtils.stopSound("slot-carpet-silk-way");
      AudioUtils.playSound("slot-carpet-silk-way", 0, 4);
    } else if (
      state.userData.settings.mevaho_mode === "fruit" &&
      state.currentGameId === "loto-pamir-legends"
    ) {
      AudioUtils.stopSound("slot-carpet-pamir-legends");
      AudioUtils.playSound("slot-carpet-pamir-legends", 0, 4);
    } else if (
      state.userData.settings.mevaho_mode === "fruit" &&
      state.currentGameId === "loto-misr"
    ) {
      AudioUtils.stopSound("slot-carpet-misr");
      AudioUtils.playSound("slot-carpet-misr", 0, 4);
    }

    try {
      const result = await api.makeBonusTicket(payload);
      commit("setIsBonusOn", true);
      commit("setShowBonusPersons", true);
      window.setTimeout(
        () => {
          commit("resetDigitWinnings");
          storeEvents.emit("createTicket");
        },
        state.userData.settings.mevaho_mode === "digit"
          ? MAKE_TICKET_DELAY_DIGIT
          : MAKE_TICKET_DELAY
      );
      commit("setNewCreated");
    } catch (e: any) {
      console.error("error: ", e);
    }
  },

  makeBonusInBonusTicket({ state, commit, dispatch }: ACtx) {
    const isFruitMode = state.userData.settings.mevaho_mode === "fruit";
    const delaySecondStep = !isFruitMode
      ? BONUS_IN_BONUS_ANIM_DELAY
      : BONUS_IN_BONUS_ANIM_DELAY + BONUS_IN_BONUS_ANIM_DELAY;
    timerSwitcherBonusAnim = window.setTimeout(() => {
      storeEvents.emit("switchBonusAnim");
      if (isFruitMode && state.currentGameId === "loto-gambusaki-gold-9") {
        AudioUtils.playSound("doubling-dropout-jazz", 0);
      }
    }, BONUS_IN_BONUS_ANIM_DELAY);

    timerMakeBonusInBonusTicket = window.setTimeout(() => {
      //  commit("setNumGames", state.settings.bonus_games.secondary_num_games);
      commit("setShowBonusModal", { show: true, start: true });
    }, delaySecondStep);
  },

  async cancelTimerMakeBonusInBonusTicket({ state, commit, dispatch }: ACtx) {
    window.clearTimeout(timerMakeBonusInBonusTicket);
    window.clearTimeout(timerSwitcherBonusAnim);
    // commit("setNumGames", state.settings.bonus_games.secondary_num_games);
    commit("setShowBonusModal", { show: true, start: true });
  },
};

export const getters = {};
