import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/images/ui/man-kissaho.webp'
import _imports_1 from '@/images/ui/man-pearl.webp'
import _imports_2 from '@/images/ui/man-misr.webp'
import _imports_3 from '@/images/backgrounds/chain-kissaho-mobile.png'
import _imports_4 from '@/images/backgrounds/bamboo-silk-way.png'
import _imports_5 from '@/images/backgrounds/separator-pamir-mobile.webp'
import _imports_6 from '@/images/backgrounds/separator-astro-mobile.png'
import _imports_7 from '@/images/backgrounds/pearl-bamboo.png'
import _imports_8 from '@/images/borders/line-misr.png'


const _withScopeId = n => (_pushScopeId("data-v-292be7e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "doubling-mobile" }
const _hoisted_2 = { class: "doubling-mobile__main" }
const _hoisted_3 = {
  key: 3,
  class: "doubling-mobile__choice"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { key: 4 }
const _hoisted_9 = { key: 5 }
const _hoisted_10 = { key: 6 }
const _hoisted_11 = {
  key: 4,
  class: "doubling-mobile__man",
  src: _imports_0,
  alt: "man with key"
}
const _hoisted_12 = {
  key: 5,
  class: "doubling-mobile__man",
  src: _imports_1,
  alt: "man pearl"
}
const _hoisted_13 = {
  key: 6,
  class: "doubling-mobile__man",
  src: _imports_2,
  alt: "man misr"
}
const _hoisted_14 = {
  key: 0,
  class: "doubling-mobile__chain",
  src: _imports_3,
  alt: "chain"
}
const _hoisted_15 = {
  key: 1,
  class: "doubling-mobile__chain",
  src: _imports_4,
  alt: "bamboo"
}
const _hoisted_16 = {
  key: 2,
  class: "doubling-mobile__chain",
  src: _imports_5,
  alt: "linepamir"
}
const _hoisted_17 = {
  key: 3,
  class: "doubling-mobile__chain",
  src: _imports_6,
  alt: "lineastro"
}
const _hoisted_18 = {
  key: 4,
  class: "doubling-mobile__chain",
  src: _imports_7,
  alt: "line"
}
const _hoisted_19 = {
  key: 5,
  class: "doubling-mobile__chain",
  src: _imports_8,
  alt: "line misr"
}
const _hoisted_20 = { class: "doubling-mobile__wrapper" }
const _hoisted_21 = { class: "doubling-mobile__group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileHeader = _resolveComponent("MobileHeader")!
  const _component_LastBoxesRow = _resolveComponent("LastBoxesRow")!
  const _component_ScoreboardWrapper = _resolveComponent("ScoreboardWrapper")!
  const _component_InputNice = _resolveComponent("InputNice")!
  const _component_DoublingBoxesSilkWay = _resolveComponent("DoublingBoxesSilkWay")!
  const _component_DoublingBoxesMisr = _resolveComponent("DoublingBoxesMisr")!
  const _component_DoublingBoxesPamir = _resolveComponent("DoublingBoxesPamir")!
  const _component_DoublingBoxesAstrologer = _resolveComponent("DoublingBoxesAstrologer")!
  const _component_DoublingBoxesMevahoGold = _resolveComponent("DoublingBoxesMevahoGold")!
  const _component_DoublingBoxes = _resolveComponent("DoublingBoxes")!
  const _component_InputFlame = _resolveComponent("InputFlame")!
  const _component_PurchasePanel = _resolveComponent("PurchasePanel")!
  const _component_ControlPanel = _resolveComponent("ControlPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MobileHeader),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isMevahoGold)
        ? (_openBlock(), _createBlock(_component_LastBoxesRow, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.isMevahoGold)
        ? (_openBlock(), _createBlock(_component_ScoreboardWrapper, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.showPayout)
        ? (_openBlock(), _createBlock(_component_InputNice, {
            key: 2,
            class: "doubling-mobile__result",
            value: _ctx.lastPayout
          }, null, 8, ["value"]))
        : _createCommentVNode("", true),
      (_ctx.showText && !_ctx.isMevahoGold)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.isSilkWay)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("chooseBag")), 1))
              : (_ctx.isKissaho)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t("chooseShield")), 1))
                : (_ctx.isPearl)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("chooseShell")), 1))
                  : (_ctx.isAstro)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("chooseMap")), 1))
                    : (_ctx.isMisr)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("chooseBasket")), 1))
                      : (_ctx.isPamirLegends)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("chooseRock")), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("chooseBox")), 1))
          ]))
        : _createCommentVNode("", true),
      (_ctx.isKissaho)
        ? (_openBlock(), _createElementBlock("img", _hoisted_11))
        : _createCommentVNode("", true),
      (_ctx.isPearl)
        ? (_openBlock(), _createElementBlock("img", _hoisted_12))
        : _createCommentVNode("", true),
      (_ctx.isMisr)
        ? (_openBlock(), _createElementBlock("img", _hoisted_13))
        : _createCommentVNode("", true),
      (_ctx.isSilkWay)
        ? (_openBlock(), _createBlock(_component_DoublingBoxesSilkWay, { key: 7 }))
        : (_ctx.isMisr)
          ? (_openBlock(), _createBlock(_component_DoublingBoxesMisr, { key: 8 }))
          : (_ctx.isPamirLegends)
            ? (_openBlock(), _createBlock(_component_DoublingBoxesPamir, { key: 9 }))
            : (_ctx.isAstro)
              ? (_openBlock(), _createBlock(_component_DoublingBoxesAstrologer, { key: 10 }))
              : (_ctx.isMevahoGold)
                ? (_openBlock(), _createBlock(_component_DoublingBoxesMevahoGold, { key: 11 }))
                : (_openBlock(), _createBlock(_component_DoublingBoxes, {
                    key: 12,
                    onAnimating: _ctx.showTitle
                  }, null, 8, ["onAnimating"]))
    ]),
    (_ctx.isKissaho)
      ? (_openBlock(), _createElementBlock("img", _hoisted_14))
      : _createCommentVNode("", true),
    (_ctx.isSilkWay)
      ? (_openBlock(), _createElementBlock("img", _hoisted_15))
      : _createCommentVNode("", true),
    (_ctx.isPamirLegends)
      ? (_openBlock(), _createElementBlock("img", _hoisted_16))
      : _createCommentVNode("", true),
    (_ctx.isAstro)
      ? (_openBlock(), _createElementBlock("img", _hoisted_17))
      : _createCommentVNode("", true),
    (_ctx.isPearl)
      ? (_openBlock(), _createElementBlock("img", _hoisted_18))
      : _createCommentVNode("", true),
    (_ctx.isMisr)
      ? (_openBlock(), _createElementBlock("img", _hoisted_19))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("div", _hoisted_21, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expectedWinnings, (item, idx) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
            (_ctx.isInRange(idx))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.fireproof && _ctx.fireproof === idx)
                    ? (_openBlock(), _createBlock(_component_InputFlame, {
                        key: 0,
                        smallFont: "",
                        ultraSmallFont: _ctx.hasBigNumber,
                        value: item,
                        color: idx === _ctx.stepCount ? 'yellow' : '',
                        style: _normalizeStyle({ order: _ctx.expectedWinnings.length - idx }),
                        class: _normalizeClass({ '--opacity': idx > _ctx.stepCount }),
                        animation: _ctx.stepCount === _ctx.fireproof
                      }, null, 8, ["ultraSmallFont", "value", "color", "style", "class", "animation"]))
                    : (_openBlock(), _createBlock(_component_InputNice, {
                        key: 1,
                        smallFont: "",
                        ultraSmallFont: _ctx.hasBigNumber,
                        value: item,
                        color: idx === _ctx.stepCount ? 'yellow' : '',
                        style: _normalizeStyle({ order: _ctx.expectedWinnings.length - idx }),
                        class: _normalizeClass({ '--opacity': idx > _ctx.stepCount })
                      }, null, 8, ["ultraSmallFont", "value", "color", "style", "class"]))
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ]),
    _createVNode(_component_PurchasePanel, { class: "doubling-mobile__panel" }),
    (!_ctx.isMaintenance && !_ctx.isBetstop)
      ? (_openBlock(), _createBlock(_component_ControlPanel, { key: 6 }))
      : _createCommentVNode("", true)
  ]))
}