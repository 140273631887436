
import { defineComponent } from "vue";

export default defineComponent({
  name: "switcher-checkbox",
  data() {
    return {};
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    label: String,
  },
  computed: {},
  methods: {
    changeChecked() {},
  },
});
