import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["doubling-boxes", { '--disabled': _ctx.disabled || _ctx.isMaintenance || _ctx.isBetstop }])
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goPlay('left'))),
      class: "doubling-boxes__left"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["doubling-box__left --won", { '--show': _ctx.wonLeft }])
      }, null, 2),
      (_ctx.isKissaho)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["doubling-box__leftGold --gold", { '--show': _ctx.wonLeft }])
          }, null, 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["doubling-box__left --lost", { '--show': _ctx.lostLeft }])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["doubling-box__left --init", { '--show': !_ctx.wonLeft && !_ctx.lostLeft }])
      }, null, 2)
    ]),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goPlay('right'))),
      class: "doubling-boxes__right"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["doubling-box__right --won", { '--show': _ctx.wonRight }])
      }, null, 2),
      (_ctx.isKissaho)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["doubling-box__rightGold --gold", { '--show': _ctx.wonRight }])
          }, null, 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["doubling-box__right --lost", { '--show': _ctx.lostRight }])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["doubling-box__right --init", { '--show': !_ctx.wonRight && !_ctx.lostRight }])
      }, null, 2)
    ])
  ], 2))
}