
import { defineComponent, PropType } from "vue";
import gsap from "gsap";

export default defineComponent({
  name: "input-nice",
  props: {
    color: String,
    value: {
      required: true,
      type: Number,
    },
    smallFont: Boolean,
    opacity: Boolean,
    blink: Boolean,
    ultraSmallFont: Boolean,
  },
  computed: {
    formatValue(): string {
      return (this.value / 100).toFixed(2);
    },
    stylesCss(): object {
      return {
        "--blink": this.blink,
        "--yellow": this.color === "yellow",
        "--opacity": this.opacity,
      };
    },
  },
  methods: {},
});
