
import { defineComponent } from "vue";
import store from "@/store";
import BurgerModal from "@/components/Modals/BurgerModal.vue";
import MyButton from "@/components/UI/MyButton.vue";
import DESIGN_TYPE from "@/main";

export default defineComponent({
  name: "modal-replenish",
  components: { MyButton, BurgerModal },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSupportPostMessage() {
      return store.state.supportPostMessage[DESIGN_TYPE];
    },
    isMobile(): boolean {
      return store.state.uiVersion !== "desktop";
    },
  },
  methods: {
    closeModal() {
      store.dispatch("toggleModal", "replenish");
    },
    closeModalPostMessage() {
      this.closeModal();
      window.parent.postMessage("openReplenish", "*");
    },
  },
});
