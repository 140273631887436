
import { defineComponent } from "vue";
import MainContainer from "@/components/MainContainer.vue";
import MenuWrapper from "@/components/MenuWrapper.vue";
import DoublingHeader from "@/components/DoublingHeader.vue";
import PurchasePanel from "@/components/PurchasePanel.vue";
import InputNice from "@/components/UI/InputNice.vue";
import store from "@/store";
import MobileHeader from "@/components/Mobile/MobileHeader.vue";
import DoublingBoxes from "@/components/DoublingBoxes.vue";
import DoublingBoxesSilkWay from "@/components/DoublingBoxesSilkWay.vue";
import DoublingBoxesMisr from "@/components/DoublingBoxesMisr.vue";
import DoublingBoxesPamir from "@/components/DoublingBoxesPamir.vue";
import DoublingBoxesAstrologer from "@/components/DoublingBoxesAstrologer.vue";
import DoublingBoxesMevahoGold from "@/components/DoublingBoxesMevahoGold.vue";
import { OPEN_BOX_TIMEOUT } from "@/constants";
import ControlPanel from "@/components/Panels/ControlPanel.vue";
import LastBoxesRow from "@/components/Mobile/LastBoxesRow.vue";
import InputFlame from "@/components/UI/InputFlame.vue";
import ScoreboardWrapper from "@/components/Scoreboards/ScoreboardWrapper.vue";

export default defineComponent({
  name: "doubling-mobile",
  components: {
    InputFlame,
    ControlPanel,
    DoublingBoxes,
    MobileHeader,
    InputNice,
    PurchasePanel,
    DoublingHeader,
    ScoreboardWrapper,
    MenuWrapper,
    MainContainer,
    LastBoxesRow,
    DoublingBoxesSilkWay,
    DoublingBoxesMisr,
    DoublingBoxesPamir,
    DoublingBoxesAstrologer,
    DoublingBoxesMevahoGold,
  },
  data() {
    return {
      showPayout: false,
      showText: true,
    };
  },
  computed: {
    lastPayout(): number {
      return store.state.ui.lastPayout;
    },
    expectedWinnings(): number[] {
      return store.state.doubling.expectedWinnings;
    },

    stepCount(): number {
      return store.state.doubling.countGame;
    },

    isMaintenance(): boolean {
      return store.state.session.maintenance;
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    hasBigNumber(): boolean {
      return !!this.expectedWinnings.find((number) => String(number).length > 6);
    },
    fireproof(): number | undefined {
      const fireproof = store.state.settings.doubling_game.fireproof_stage;
      return fireproof.enabled ? fireproof.game_number : undefined;
    },
    isKissaho(): boolean {
      return store.state.currentGameId === "loto-kissaho";
    },
    isSilkWay(): boolean {
      return store.state.currentGameId === "loto-silk-way";
    },
    isPamirLegends(): boolean {
      return store.state.currentGameId === "loto-pamir-legends";
    },
    isPearl(): boolean {
      return store.state.currentGameId === "loto-pearl";
    },
    isAstro(): boolean {
      return store.state.currentGameId === "loto-astrologer";
    },
    isMevahoGold(): boolean {
      return store.state.currentGameId === "loto-mevaho-gold";
    },

    isMisr(): boolean {
      return store.state.currentGameId === "loto-misr";
    },
  },
  methods: {
    showTitle({ isOn, short }: { isOn: boolean; short: boolean }) {
      const delayAnim = short ? 0 : OPEN_BOX_TIMEOUT;
      if (isOn) {
        this.showText = false;
        setTimeout(() => {
          this.showPayout = true;
        }, delayAnim);
      } else {
        this.showPayout = false;
        this.showText = true;
      }
    },
    isInRange(idx: number) {
      if (this.isMevahoGold) {
        if (this.stepCount === 0 || this.stepCount === 1) {
          return idx >= 0 && idx <= 2;
        } else if (this.stepCount === 2) {
          return idx >= 1 && idx <= 3;
        } else if (this.stepCount === 3) {
          return idx >= 2 && idx <= 4;
        } else if (this.stepCount === 4 || this.stepCount === 5) {
          return idx >= 3 && idx <= 5;
        }
      }
    },
  },
  watch: {},
  created() {},
});
