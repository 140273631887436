
import { defineComponent } from "vue";
import store from "@/store";
import BurgerModal from "@/components/Modals/BurgerModal.vue";
import MyButton from "@/components/UI/MyButton.vue";

const CLOSE_TIMEOUT = 5 * 1000;

export default defineComponent({
  name: "modal-expired",
  components: { MyButton, BurgerModal },
  data() {
    return {
      closeTimeout: undefined as number | undefined,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile(): boolean {
      return store.state.uiVersion !== "desktop";
    },
  },
  methods: {
    closeModal() {
      store.dispatch("closeModal", "expired");
    },
  },
  watch: {
    open(n) {
      if (n) {
        this.closeTimeout = window.setTimeout(() => {
          this.closeModal();
        }, CLOSE_TIMEOUT);
      } else {
        clearTimeout(this.closeTimeout);
      }
    },
  },
});
