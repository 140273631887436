
import store from "@/store";
import { defineComponent } from "vue";

export default defineComponent({
  name: "basic-modal",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    leave(el: HTMLElement, done: Function) {
      // add transition classes to modal content
      const x = el.firstElementChild;
      if (x) {
        x.classList.add("slide-leave-active", "slide-leave-to");
      }
      setTimeout(() => {
        done();
      }, 250);
    },
    clickOutside() {
      if (this.closable) {
        this.hide();
      }
    },
    hide() {
      this.$emit("close");
    },
  },
  beforeDestroy() {},
});
