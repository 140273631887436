
import { defineComponent } from "vue";
import InfoModal from "@/components/Modals/InfoModal.vue";
import store from "@/store";
import MevahoPayments from "@/components/Tables/MevahoPayments.vue";

export default defineComponent({
  name: "modal-payments",
  components: { MevahoPayments, InfoModal },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    closeModal() {
      store.dispatch("toggleModal", "payments");
    },
  },
});
