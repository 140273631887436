import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/images/boxes/boxes-left-arrow.png'
import _imports_1 from '@/images/boxes/boxes-right-arrow.png'


const _withScopeId = n => (_pushScopeId("data-v-387319b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "last-boxes-row" }
const _hoisted_2 = { src: _imports_0 }
const _hoisted_3 = { class: "last-boxes-row__boxesContainer" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { src: _imports_1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.updateLastDoublingGames.length > 4 && !_ctx.isMevahoGold)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "last-boxes-row__arrows",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollToEnd && _ctx.scrollToEnd(...args)))
        }, [
          _withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [
            [_vShow, _ctx.isVisibleLeftArrow]
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "last-boxes-row__container",
      ref: "scroll",
      onScroll: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.scrollHandler && _ctx.scrollHandler(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.updateLastDoublingGames, (box, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: box.id
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: _normalizeClass({
              'last-boxes-row__firstBox': index != 0,
              'last-boxes-row__allBoxes': index === 0,
            }),
              src: 
              (box.sign === 'right' && box.status === 'won') ||
              (box.sign === 'left' && box.status === 'lost')
                ? index === 0
                  ? _ctx.imgRightActiveBox
                  : _ctx.imgRightOpenBox
                : index === 0
                ? _ctx.imgLeftActiveBox
                : _ctx.imgLeftOpenBox
            
            }, null, 10, _hoisted_4)
          ])
        ]))
      }), 128))
    ], 544),
    (_ctx.updateLastDoublingGames.length > 4 && !_ctx.isMevahoGold)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "last-boxes-row__arrows",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.scrollToStart && _ctx.scrollToStart(...args)))
        }, [
          _withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [
            [_vShow, _ctx.isVisibleRightArrow]
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}