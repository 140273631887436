
import { defineComponent } from "vue";
import MenuTop from "@/components/MenuTop.vue";
import MenuWrapper from "@/components/MenuWrapper.vue";
import store from "@/store";
import { GameId } from "@/types/user";

export default defineComponent({
  name: "mobile-header",
  components: { MenuWrapper, MenuTop },
  computed: {
    gameId(): GameId {
      return store.state.currentGameId;
    },
  },
});
