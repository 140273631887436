import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_BigButton = _resolveComponent("BigButton")!

  return (_ctx.isDesktop && !_ctx.bonus)
    ? (_openBlock(), _createBlock(_component_BasicButton, {
        key: 0,
        onClick: _ctx.makeTicket,
        class: _normalizeClass(["play-game", { '--blink': !_ctx.hidden }]),
        mode: "game",
        disabled: _ctx.hidden
      }, null, 8, ["onClick", "class", "disabled"]))
    : (_ctx.isDesktop && _ctx.bonus && !_ctx.isBonusGame)
      ? (_openBlock(), _createBlock(_component_BasicButton, {
          key: 1,
          onClick: _ctx.makeBonusTicket,
          class: _normalizeClass(["play-game", { '--blink': !_ctx.isBonusGame }]),
          mode: "game",
          disabled: _ctx.isBonusGame
        }, null, 8, ["onClick", "class", "disabled"]))
      : (_ctx.isDesktop && _ctx.bonus && _ctx.isBonusGame)
        ? (_openBlock(), _createBlock(_component_BasicButton, {
            key: 2,
            onClick: _ctx.skipAnimation,
            class: "play-game",
            mode: "skip",
            disabled: _ctx.isSkipDisabled
          }, null, 8, ["onClick", "disabled"]))
        : (!_ctx.isBetstop && !_ctx.bonus)
          ? (_openBlock(), _createBlock(_component_BigButton, {
              key: 3,
              class: _normalizeClass({ '--blink': !_ctx.hidden }),
              onClick: _ctx.makeTicket,
              text: _ctx.$t('buttonPlay'),
              disabled: _ctx.hidden
            }, null, 8, ["class", "onClick", "text", "disabled"]))
          : (!_ctx.isBetstop && _ctx.bonus)
            ? (_openBlock(), _createBlock(_component_BigButton, {
                key: 4,
                onClick: _ctx.makeBonusTicket,
                text: _ctx.$t('buttonPlay'),
                class: _normalizeClass({ '--blink': !_ctx.isBonusGame }),
                disabled: _ctx.isBonusGame
              }, null, 8, ["onClick", "text", "class", "disabled"]))
            : _createCommentVNode("", true)
}