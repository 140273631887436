import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dbf5965a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "simple-timer"
}
const _hoisted_2 = {
  key: 1,
  class: "countdown-timer"
}
const _hoisted_3 = { class: "countdown-timer__item" }
const _hoisted_4 = { class: "countdown-timer__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.simple)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.formatMin) + ":" + _toDisplayString(_ctx.formatSec), 1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.formatMin), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_ctx.formatSec), 1)
        ])
      ]))
}