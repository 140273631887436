
import { defineComponent } from "vue";
export default defineComponent({
  name: "start-screen",
  props: {
    progress: {
      required: true,
      type: Number,
      default: 0,
    },
  },
});
