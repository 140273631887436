import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuWrapper = _resolveComponent("MenuWrapper")!
  const _component_MainContainer = _resolveComponent("MainContainer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["main-screen", { '--isBonus': _ctx.isBonusGamePamirLegends }]),
    style: _normalizeStyle(_ctx.isPamir && _ctx.pamirBackgroundsStyle)
  }, [
    _createVNode(_component_MenuWrapper),
    _createVNode(_component_MainContainer)
  ], 6))
}