
import { defineComponent } from "vue";
import store from "@/store";
import { getCurrencyIcon } from "@/utils";

export default defineComponent({
  components: {},
  props: {},
  computed: {
    underlineCss(): object {
      return {
        "--underline": this.currency === 417,
      };
    },
    currency(): number {
      return store.state.userData.currency;
    },
    currencyIcon(): string {
      return getCurrencyIcon(this.currency);
    },
    rbon(): boolean {
      return this.currency === 1002;
    },
  },
});
