import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { UserData } from "@/types/user";

const randomMaxBet = () => Math.floor(1000 * Math.random()) * 100;

export const fakeUserData = (): UserData => ({
  username: "User",
  balance: 100 * 100,
  currency: 972,
  language: "en",
  fallback_language: "en",
  // last_state: {
  //   ticket: {
  //     ticket_id: 3630,
  //     draw_id: 7923489,
  //     status: "won",
  //     amount: 50,
  //     payout: 600,
  //     currency: 972,
  //     combination: "combi-5",
  //     received: 1685526352634,
  //     processed: 1685526352730,
  //     calculated: 1685526353133,
  //     draw: {
  //       id: 7923489,
  //       numbers: [6, 5, 0, 2, 3, 5, 4, 3, 0, 7, 1, 1, 5, 7, 0],
  //     },
  //     doubling_games: [
  //       {
  //         doubling_game_id: 284,
  //         status: "won",
  //         amount: 150,
  //         payout: 300,
  //       },
  //       {
  //         doubling_game_id: 285,
  //         status: "won",
  //         amount: 300,
  //         payout: 600,
  //       },
  //     ],
  //   },
  //   stage: "participate",
  // },
  settings: {
    mevaho_mode: "fruit",
    sound: "on",
    background_sound: "on",
    widescreen: "off",
    autogame_continuation: "off",
    autogame_start: "off",
  },
});

export const mockedFakeUserData = fakeUserData();
