import { UpdateTicket } from "@/types/tickets";

const tickets: UpdateTicket[] = [
  {
    ticket_id: 48530,
    draw_id: 12874573,
    status: "won",
    amount: 225,
    payout: 450,
    currency: 972,
    combination: "combi-9",
    received: 1711243701102,
    processed: 1711243701184,
    calculated: 1711243701934,
    draw: {
      id: 12874573,
      // numbers: [9, 0, 0, 0, 0, 11, 10, 10, 12, 4, 5, 4, 10, 11, 3],
      numbers: [2, 4, 0, 5, 7, 0, 5, 6, 4, 5, 5, 3, 0, 0, 6],
    },
    has_free_tickets: false,
    is_free_ticket: false,
    has_doubling: true,
    free_tickets_left: 10,
    free_tickets_total: 10,
    payout_by_line: {
      // lines_payout: [0, 2750, 0, 250, 125, 0, 0, 0, 0],
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      // bonus_payout: 450,
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48531,
    draw_id: 12874858,
    status: "won",
    amount: 225,
    payout: 250,
    currency: 972,
    combination: "combi-9",
    received: 1711243989668,
    processed: 1711243989840,
    calculated: 1711243990606,
    draw: {
      id: 12874858,
      numbers: [3, 6, 1, 7, 4, 5, 3, 3, 7, 4, 6, 3, 3, 3, 2],
    },
    has_free_tickets: false,
    is_free_ticket: false,
    has_doubling: true,
    free_tickets_left: 9,
    free_tickets_total: 10,
    payout_by_line: {
      lines_payout: [125, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48532,
    draw_id: 12874866,
    status: "won",
    amount: 225,
    payout: 125,
    currency: 972,
    combination: "combi-9",
    received: 1711243997983,
    processed: 1711243998101,
    calculated: 1711243998680,
    draw: {
      id: 12874866,
      numbers: [3, 3, 0, 4, 7, 3, 1, 3, 6, 1, 1, 1, 5, 7, 6],
    },
    has_free_tickets: false,
    is_free_ticket: false,
    has_doubling: true,
    free_tickets_left: 8,
    free_tickets_total: 10,
    payout_by_line: {
      lines_payout: [0, 0, 0, 50, 0, 50],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48533,
    draw_id: 12874872,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244003778,
    processed: 1711244003881,
    calculated: 1711244004735,
    draw: {
      id: 12874872,
      numbers: [0, 0, 3, 2, 5, 4, 5, 2, 1, 5, 9, 3, 5, 7, 3],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 7,
    free_tickets_total: 10,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48534,
    draw_id: 12874876,
    status: "won",
    amount: 225,
    payout: 3125,
    currency: 972,
    combination: "combi-9",
    received: 1711244007568,
    processed: 1711244007689,
    calculated: 1711244008765,
    draw: {
      id: 12874876,
      numbers: [4, 2, 3, 2, 0, 9, 9, 1, 1, 1, 7, 0, 9, 2, 1],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 6,
    free_tickets_total: 10,
    payout_by_line: {
      lines_payout: [0, 2750, 0, 250, 125, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48535,
    draw_id: 12874888,
    status: "won",
    amount: 225,
    payout: 375,
    currency: 972,
    combination: "combi-9",
    received: 1711244019867,
    processed: 1711244019989,
    calculated: 1711244020873,
    draw: {
      id: 12874888,
      numbers: [1, 1, 2, 0, 3, 3, 4, 3, 1, 3, 3, 9, 3, 7, 0],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 5,
    free_tickets_total: 10,
    payout_by_line: {
      lines_payout: [0, 0, 125, 0, 0, 0, 125, 0, 125],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48536,
    draw_id: 12874898,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244030174,
    processed: 1711244030293,
    calculated: 1711244030960,
    draw: {
      id: 12874898,
      numbers: [3, 2, 4, 8, 1, 1, 4, 4, 1, 2, 2, 1, 4, 1, 8],
    },
    has_free_tickets: false,
    is_free_ticket: false,
    has_doubling: true,
    free_tickets_left: 0,
    free_tickets_total: 10,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  /*{
    ticket_id: 48537,
    draw_id: 12874902,
    status: "won",
    amount: 225,
    payout: 450,
    currency: 972,
    combination: "combi-9",
    received: 1711244033973,
    processed: 1711244034068,
    calculated: 1711244035003,
    draw: {
      id: 12874902,
      numbers: [6, 3, 0, 3, 0, 3, 0, 9, 3, 3, 0, 5, 0, 4, 0],
    },
    has_free_tickets: true,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 13,
    free_tickets_total: 20,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 450,
    },
  },
  {
    ticket_id: 48538,
    draw_id: 12874908,
    status: "won",
    amount: 225,
    payout: 250,
    currency: 972,
    combination: "combi-9",
    received: 1711243989668,
    processed: 1711243989840,
    calculated: 1711243990606,
    draw: {
      id: 12874908,
      numbers: [2, 9, 2, 3, 7, 6, 2, 3, 2, 0, 4, 1, 3, 3, 5],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 12,
    free_tickets_total: 20,
    payout_by_line: {
      lines_payout: [125, 0, 0, 0, 0, 125, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48539,
    draw_id: 12874912,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244044822,
    processed: 1711244044914,
    calculated: 1711244045203,
    draw: {
      id: 12874912,
      numbers: [0, 7, 3, 5, 3, 8, 7, 4, 3, 2, 1, 3, 1, 2, 4],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 11,
    free_tickets_total: 20,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48540,
    draw_id: 12874916,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244048610,
    processed: 1711244048707,
    calculated: 1711244049263,
    draw: {
      id: 12874916,
      numbers: [5, 1, 3, 2, 8, 7, 4, 5, 3, 1, 2, 4, 2, 5, 3],
    },
    has_free_tickets: false,
    is_free_ticket: false,
    has_doubling: true,
    free_tickets_left: 10,
    free_tickets_total: 20,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
   {
    ticket_id: 48541,
    draw_id: 12874920,
    status: "won",
    amount: 225,
    payout: 125,
    currency: 972,
    combination: "combi-9",
    received: 1711244052400,
    processed: 1711244052500,
    calculated: 1711244053344,
    draw: {
      id: 12874920,
      numbers: [6, 2, 1, 5, 2, 2, 9, 0, 0, 2, 3, 4, 7, 0, 5],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 9,
    free_tickets_total: 20,
    payout_by_line: {
      lines_payout: [0, 0, 0, 125, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48542,
    draw_id: 12874926,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244058192,
    processed: 1711244058316,
    calculated: 1711244059350,
    draw: {
      id: 12874926,
      numbers: [4, 0, 4, 5, 3, 1, 8, 5, 2, 1, 3, 5, 0, 0, 0],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 8,
    free_tickets_total: 20,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48543,
    draw_id: 12874930,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244061981,
    processed: 1711244062071,
    calculated: 1711244063417,
    draw: {
      id: 12874930,
      numbers: [2, 1, 7, 9, 0, 3, 7, 5, 5, 0, 5, 3, 8, 0, 8],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 7,
    free_tickets_total: 20,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48544,
    draw_id: 12874934,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244065770,
    processed: 1711244065873,
    calculated: 1711244067445,
    draw: {
      id: 12874934,
      numbers: [6, 1, 2, 4, 2, 4, 3, 1, 4, 1, 5, 2, 5, 2, 1],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 6,
    free_tickets_total: 20,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48545,
    draw_id: 12874938,
    status: "won",
    amount: 225,
    payout: 125,
    currency: 972,
    combination: "combi-9",
    received: 1711244069708,
    processed: 1711244069797,
    calculated: 1711244071484,
    draw: {
      id: 12874938,
      numbers: [2, 2, 2, 5, 8, 5, 5, 1, 9, 0, 4, 1, 2, 1, 0],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 5,
    free_tickets_total: 20,
    payout_by_line: {
      lines_payout: [125, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48546,
    draw_id: 12874944,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244075663,
    processed: 1711244075759,
    calculated: 1711244077538,
    draw: {
      id: 12874944,
      numbers: [2, 5, 8, 4, 3, 4, 3, 3, 1, 9, 2, 1, 0, 2, 1],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 4,
    free_tickets_total: 20,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48547,
    draw_id: 12874948,
    status: "won",
    amount: 225,
    payout: 450,
    currency: 972,
    combination: "combi-9",
    received: 1711244079745,
    processed: 1711244079849,
    calculated: 1711244081575,
    draw: {
      id: 12874948,
      numbers: [3, 5, 5, 2, 3, 0, 7, 0, 2, 8, 6, 0, 6, 8, 1],
    },
    has_free_tickets: true,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 13,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 450,
    },
  },
  {
    ticket_id: 48548,
    draw_id: 12874954,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244086650,
    processed: 1711244086747,
    calculated: 1711244087636,
    draw: {
      id: 12874954,
      numbers: [6, 5, 3, 1, 3, 7, 2, 4, 2, 2, 4, 3, 5, 0, 2],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 12,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48549,
    draw_id: 12874958,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244090441,
    processed: 1711244090646,
    calculated: 1711244091691,
    draw: {
      id: 12874958,
      numbers: [3, 4, 4, 8, 3, 6, 1, 1, 9, 2, 6, 7, 4, 5, 5],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 11,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48550,
    draw_id: 12874962,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244094249,
    processed: 1711244094343,
    calculated: 1711244095741,
    draw: {
      id: 12874962,
      numbers: [9, 4, 0, 5, 1, 1, 0, 6, 3, 4, 9, 0, 0, 2, 3],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 10,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48551,
    draw_id: 12874966,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244098039,
    processed: 1711244098133,
    calculated: 1711244099775,
    draw: {
      id: 12874966,
      numbers: [2, 2, 3, 6, 1, 5, 7, 4, 1, 0, 0, 1, 5, 4, 2],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 9,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48552,
    draw_id: 12874970,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244101954,
    processed: 1711244102041,
    calculated: 1711244103827,
    draw: {
      id: 12874970,
      numbers: [1, 2, 2, 0, 4, 3, 6, 0, 0, 4, 1, 3, 4, 9, 1],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 8,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48553,
    draw_id: 12874974,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244106016,
    processed: 1711244106104,
    calculated: 1711244107874,
    draw: {
      id: 12874974,
      numbers: [2, 0, 1, 6, 1, 4, 4, 3, 9, 7, 1, 5, 1, 8, 2],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 7,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48554,
    draw_id: 12874978,
    status: "won",
    amount: 225,
    payout: 125,
    currency: 972,
    combination: "combi-9",
    received: 1711244110054,
    processed: 1711244110151,
    calculated: 1711244111915,
    draw: {
      id: 12874978,
      numbers: [1, 9, 7, 5, 0, 6, 0, 5, 9, 0, 3, 2, 3, 8, 0],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 6,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 125, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48555,
    draw_id: 12874982,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244114269,
    processed: 1711244114378,
    calculated: 1711244115957,
    draw: {
      id: 12874982,
      numbers: [3, 0, 4, 2, 0, 3, 5, 1, 5, 0, 1, 0, 9, 2, 0],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 5,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48556,
    draw_id: 12874986,
    status: "won",
    amount: 225,
    payout: 250,
    currency: 972,
    combination: "combi-9",
    received: 1711244118146,
    processed: 1711244118259,
    calculated: 1711244120019,
    draw: {
      id: 12874986,
      numbers: [4, 7, 2, 2, 6, 0, 4, 6, 1, 2, 1, 4, 4, 0, 0],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 4,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 250, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48557,
    draw_id: 12874990,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244122324,
    processed: 1711244122416,
    calculated: 1711244124056,
    draw: {
      id: 12874990,
      numbers: [2, 5, 6, 0, 8, 1, 0, 0, 0, 2, 2, 0, 5, 7, 4],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 3,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48558,
    draw_id: 12874994,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244126338,
    processed: 1711244126429,
    calculated: 1711244128103,
    draw: {
      id: 12874994,
      numbers: [3, 0, 2, 6, 2, 8, 1, 3, 9, 1, 3, 4, 3, 1, 5],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 2,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48559,
    draw_id: 12874998,
    status: "won",
    amount: 225,
    payout: 125,
    currency: 972,
    combination: "combi-9",
    received: 1711244130330,
    processed: 1711244130429,
    calculated: 1711244132143,
    draw: {
      id: 12874998,
      numbers: [9, 4, 1, 2, 3, 3, 1, 0, 0, 0, 4, 2, 1, 5, 0],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 1,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 125, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },
  {
    ticket_id: 48560,
    draw_id: 12875002,
    status: "lost",
    amount: 225,
    payout: 0,
    currency: 972,
    combination: "combi-9",
    received: 1711244134541,
    processed: 1711244134636,
    calculated: 1711244136184,
    draw: {
      id: 12875002,
      numbers: [8, 3, 0, 1, 4, 3, 5, 2, 1, 0, 0, 5, 3, 1, 1],
    },
    has_free_tickets: false,
    is_free_ticket: true,
    has_doubling: false,
    free_tickets_left: 0,
    free_tickets_total: 30,
    payout_by_line: {
      lines_payout: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      bonus_payout: 0,
    },
  },*/
];

export default tickets;
