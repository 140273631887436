
import { defineComponent, PropType } from "vue";
import store from "@/store";
import { TICKET_COMBINATIONS } from "@/constants";
import { CreateTicketState, UpdateTicket, UpdateTicketState } from "@/types/tickets";
import moment from "moment/moment";

export default defineComponent({
  name: "simple-info",
  data() {
    return {
      opened: true,
    };
  },
  props: {},
  computed: {
    currentTicket(): UpdateTicketState | undefined {
      return store.state.currentTicket;
    },
    sumAmount(): number | undefined {
      return this.currentTicket ? this.currentTicket.amount : undefined;
    },
    combinationLines(): number {
      const currentCombination = this.currentTicket && this.currentTicket.combination;
      if (currentCombination) {
        return TICKET_COMBINATIONS[currentCombination].number;
      } else {
        return 0;
      }
    },
    received(): string {
      const date = this.currentTicket && this.currentTicket.received;
      const gmt = moment().utcOffset() / 60;
      return moment(date).format("DD.MM.YYYY HH:mm:ss");
    },
    gmt(): number {
      return moment().utcOffset() / 60;
    },
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
    ticket(): UpdateTicket | undefined {
      return store.state.currentTicket;
    },
  },
  methods: {
    switchMode(): boolean {
      return (this.opened = !this.opened);
    },
  },
});
