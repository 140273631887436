import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12a2c115"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pamir-cell__container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pamir-cell", _ctx.styles])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        alt: "image",
        src: _ctx.imgPath,
        class: _normalizeClass(["pamir-cell__item", `--${_ctx.digit}`])
      }, null, 10, _hoisted_2)
    ])
  ], 2))
}