import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6aa32684"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "combination-control" }
const _hoisted_2 = { class: "combination-control__title" }
const _hoisted_3 = { class: "combination-control__wrapper" }
const _hoisted_4 = { class: "combination-control__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleButton = _resolveComponent("CircleButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("combination")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CircleButton, {
        onClick: _ctx.combinationSelectionMinus,
        disabled: 
          _ctx.controlDisabled || _ctx.isDoublingOn || _ctx.makeTicketDisabled || _ctx.disconnected || _ctx.promoIsOn
        
      }, null, 8, ["onClick", "disabled"]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.newCombinationNumber), 1),
      _createVNode(_component_CircleButton, {
        onClick: _ctx.combinationSelectionPlus,
        type: "plus",
        disabled: 
          _ctx.controlDisabled || _ctx.isDoublingOn || _ctx.makeTicketDisabled || _ctx.disconnected || _ctx.promoIsOn
        
      }, null, 8, ["onClick", "disabled"])
    ])
  ]))
}