import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0136c208"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "burger-modal__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    onLeave: _ctx.leave,
    css: false
  }, {
    default: _withCtx(() => [
      (_ctx.open)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["burger-modal", { '--top': _ctx.top, '--bottom': _ctx.bottom }]),
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clickOutside && _ctx.clickOutside(...args)), ["self"]))
          }, [
            _createVNode(_Transition, {
              name: "slide",
              duration: "500",
              appear: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                  (_ctx.showClose)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args))),
                        class: "fas fa-times burger-modal__close"
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 3
            })
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["onLeave"]))
}