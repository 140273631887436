import { api } from "@/api";
import { ACtx } from "@/store";
import store from "@/store";
import State from "./state";
import { ChipNominal, SettingsState } from "@/types/settings";
import { TicketCombination } from "@/types/tickets";

export const state: SettingsState = {
  chips: [],
  combinations: [],
  doubling_game: {
    confirm_delay: 5000,
    playout_delay: 15000,
    enabled: false,
    split: {
      enabled: true,
      stage_number: 1,
      min_amount: 1000,
    },
    fireproof_stage: {
      enabled: false,
      game_number: 1,
    },
  },
  bonus_games: {
    enabled: true,
    num_games: 10,
    playout_delay: 10000,
    secondary_num_games: 10,
  },
  buy_in: {
    enabled: false,
    ticket_cost_multiplier: 1,
  },
};

export const mutations = {
  updateSettings(state: State, value: SettingsState) {
    const { chips, combinations, doubling_game, bonus_games, buy_in } = value;
    // update all chips
    if (chips) {
      state.settings.chips = chips;
    }
    if (combinations) {
      state.settings.combinations = combinations;
    }
    if (doubling_game) {
      state.settings.doubling_game = doubling_game;
    }
    if (bonus_games) {
      state.settings.bonus_games = bonus_games;
    }
    if (buy_in) {
      state.settings.buy_in = buy_in;
    }
  },
};

export const getters = {
  chipNominals(state: State): ChipNominal[] {
    return state.settings.chips.map((el) => {
      return { nominal: el.nominal };
    });
  },
  chipNominalsArray(state: State): number[] {
    return state.settings.chips.map((el) => el.nominal);
  },
  ticketCombinations(state: State): TicketCombination[] {
    return state.settings.combinations.sort();
  },
};

export const actions = {
  async loadSettings({ state, commit }: ACtx) {
    const settings = await api.getSettings();
    commit("updateSettings", settings);
    if (settings.doubling_game.enabled) {
      store.dispatch("getLastDoublingGames");
    }
  },
};
