import { api } from "@/api";
import { ACtx } from "@/store";
import State from "./state";
import { DrawItem, DrawsState } from "@/types/draws";

export const state: DrawsState = {
  drawHistory: [],
};

export const mutations = {
  updateDrawHistory(state: State, draws: DrawItem[]) {
    state.draws.drawHistory = draws;
  },
};

export const actions = {
  async loadDrawHistory({ state, commit }: ACtx) {
    const payload = {
      limit: 10,
      offset: 0,
    };
    const { items, count } = await api.getDrawHistory(payload);

    commit("updateDrawHistory", items);
  },
};

export const getters = {};
