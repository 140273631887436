
import { defineComponent } from "vue";
import store from "@/store";
import { getCurrencyIcon } from "@/utils";
import { CLOSE_BONUS_IN_BONUS_MODAL } from "@/constants";
import PlayGame from "@/components/Panels/PlayGame.vue";
import { MultiButtonMode } from "@/store/ui";
import MobileButton from "@/components/UI/MobileButton.vue";

export default defineComponent({
  name: "modal-promo",
  components: {
    PlayGame,
    MobileButton,
  },
  data() {
    return {
      applyTimer: undefined as number | undefined,
    };
  },
  computed: {
    isPromoModal(): boolean {
      return store.state.ui.showPromoModal.show;
    },
    isStart(): boolean {
      return store.state.ui.showBonusModal.start;
    },
    payout(): string {
      return (store.state.ui.lastPayout / 100).toFixed(2);
    },
    currency(): number {
      return store.state.userData.currency;
    },
    currencyIcon(): string {
      return getCurrencyIcon(this.currency);
    },
    isGambusaki(): boolean {
      return (
        store.state.currentGameId === "loto-gambusaki-9" ||
        store.state.currentGameId === "loto-gambusaki-gold-9" ||
        store.state.currentGameId === "loto-khoja"
      );
    },
    promoQuantity(): number | undefined {
      return store.state.promo.promo?.quantity;
    },
    promoCombination(): string | undefined {
      return store.state.promo.promo?.combination
        ? store.state.promo.promo?.combination.replace(/\D/g, "")
        : undefined;
    },
    promoAmount(): number | undefined {
      return store.state.promo.promo?.amount ? store.state.promo.promo?.amount / 100 : undefined;
    },
    buttonMode(): MultiButtonMode {
      return store.state.ui.multiButtonMode;
    },
    isMaintenance(): boolean {
      return store.state.session.maintenance;
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    disconnected(): boolean {
      return !store.state.connected;
    },
    isGoldButton(): boolean {
      return store.state.currentGameId === "loto-gambusaki-gold-9";
    },
    textQuantity(): string {
      if (this.promoQuantity === 1) {
        return this.$t("modalPromo.ticket1");
      } else if (this.promoQuantity && this.promoQuantity > 1 && this.promoQuantity < 5) {
        return this.$t("modalPromo.ticket2");
      } else {
        return this.$t("modalPromo.ticket3");
      }
    },
    promoTicketCombination(): string | undefined {
      return store.state.promo.promo?.combination;
    },
    promoTicketAmount(): number | undefined {
      return store.state.promo.promo?.amount;
    },
    promoTicketSumAmount(): number | undefined {
      return store.state.promo.promo?.combination
        ? Number(store.state.promo.promo?.combination.replace(/\D/g, "")) *
            store.state.promo.promo?.amount
        : 0;
    },
  },
  methods: {
    leave(el: HTMLElement, done: Function) {
      // add transition classes to modal content
      const x = el.firstElementChild;
      if (x) {
        x.classList.add("slide-leave-active", "slide-leave-to");
      }
      setTimeout(() => {
        done();
      }, 250);
    },
    startTicket() {
      // store.dispatch("makePromoTicket");
      store.commit("setShowPromoModal", { show: false, start: false });
      store.commit("setPromoIsOn", true);
      // store.commit("setControlDisabled", true);
      store.commit("setNewCombination", this.promoTicketCombination);
      store.commit("setNewNominal", this.promoTicketAmount);
      store.commit("setSumAmount", this.promoTicketSumAmount);
    },
    hidePromoModal() {
      store.commit("setShowPromoModal", { show: false, start: false });
      store.commit("setPromoIsOn", false);
    },
  },
  watch: {},
});
