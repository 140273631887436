
import { defineComponent, PropType } from "vue";
import store from "@/store";
import MobileButton from "@/components/UI/MobileButton.vue";
import { AudioUtils } from "@/utils";

export default defineComponent({
  name: "nominal-button",
  components: { MobileButton },
  data() {
    return {
      value: undefined as undefined | number,
    };
  },
  props: {},
  computed: {
    nominals(): number[] {
      return store.getters.chipNominalsArray;
    },
    currentNominal(): number | undefined {
      return store.state.createTicket.amount;
    },
    controlDisabled(): boolean {
      return store.state.ui.controlDisabled;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    makeTicketDisabled(): boolean {
      return store.state.ui.makeTicketDisabled;
    },
    isMobile(): boolean {
      return store.state.uiVersion !== "desktop";
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    disconnected(): boolean {
      return !store.state.connected;
    },
    promoIsOn(): boolean {
      return store.state.ui.promoIsOn;
    },
    isPamir(): boolean {
      return store.state.currentGameId === "loto-pamir-legends";
    },
    buttonColor(): string {
      return this.isPamir ? "iceblue" : "pink";
    },
  },
  methods: {
    playAudio() {
      if (store.state.userData.settings.sound === "on") {
        AudioUtils.playSound("pressing-bet-amount-button", 0, 1);
      }
    },
    nominalSelection() {
      this.playAudio();
      const list = this.nominals;
      let idx = list.findIndex((el) => el === this.currentNominal);
      if (idx === -1 || idx === list.length - 1) {
        store.dispatch("setNewNominal", list[0]);
      } else {
        store.dispatch("setNewNominal", list[idx + 1]);
      }
    },
  },
});
