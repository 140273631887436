
import store from "@/store";
import { defineComponent, PropType } from "vue";
import storeEvents from "@/store-events";
import { OPEN_BOX_TIMEOUT, OPEN_SECOND_BOX_TIMEOUT } from "@/constants";

export default defineComponent({
  name: "doubling-boxes-misr",
  data() {
    return {
      wonLeft: false,
      wonRight: false,
      lostLeft: false,
      lostRight: false,
      boxSide: "left" as "left" | "right",
      disabled: false,
      openTimeout: undefined as number | undefined,
      rightBagWon: require("@/images/ui/box-won-misr.webp"),
      leftBagWon: require("@/images/ui/box-won-misr.webp"),
      rightBagLost: require("@/images/ui/box-lost-misr.webp"),
      leftBagLost: require("@/images/ui/box-lost-misr.webp"),
      rightBagInit: require("@/images/ui/box-closed-misr.webp"),
      leftBagInit: require("@/images/ui/box-closed-misr.webp"),
    };
  },
  created() {
    storeEvents.on("isPlayDoublingWon", this.startAnimation);
    storeEvents.on("nextPlayGame", this.resetData);
    storeEvents.on("lastPlayedGame", this.stopGame);
  },
  mounted() {
    this.preloadImages();
  },
  computed: {
    cssRight(): object {
      return {
        "--won": this.wonRight,
        "--lost": this.lostRight,
      };
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    currentImageLeft(): string {
      return this.wonLeft === true
        ? this.leftBagWon
        : this.lostLeft === true
        ? this.leftBagLost
        : this.leftBagInit;
    },
    currentImageRight(): string {
      return this.wonRight === true
        ? this.rightBagWon
        : this.lostRight === true
        ? this.rightBagLost
        : this.rightBagInit;
    },
  },
  methods: {
    preloadImages() {
      const images = [
        this.rightBagWon,
        this.rightBagLost,
        this.leftBagWon,
        this.leftBagLost,
        this.rightBagInit,
        this.leftBagInit,
      ];
      images.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    },
    goPlay(box: "left" | "right", wonRight: false | true) {
      this.boxSide = box;
      store.dispatch("applyDoublingGame", box);
      this.wonRight = wonRight;
      store.dispatch("boxesSign", box);
      this.disabled = true;
    },
    startAnimation(isWon: boolean) {
      if (isWon) {
        this.$emit("animating", { isOn: true, short: false });
        this.boxSide === "left" ? (this.wonLeft = true) : (this.wonRight = true);
      } else {
        this.$emit("animating", { isOn: true, short: false });
        this.boxSide === "left" ? (this.lostLeft = true) : (this.lostRight = true);
        this.openTimeout = window.setTimeout(() => {
          this.boxSide === "left" ? (this.wonRight = true) : (this.wonLeft = true);
        }, OPEN_SECOND_BOX_TIMEOUT);
      }
    },
    resetData() {
      this.wonLeft = false;
      this.wonRight = false;
      this.lostLeft = false;
      this.lostRight = false;
      this.boxSide = "left";
      this.disabled = false;
      window.clearTimeout(this.openTimeout);
      this.$emit("animating", { isOn: false, short: false });
    },
    stopGame() {
      this.resetData();
      this.disabled = true;
    },
  },
  beforeUnmount() {
    storeEvents.off("isPlayDoublingWon", this.startAnimation);
    storeEvents.off("nextPlayGame", this.resetData);
    storeEvents.off("lastPlayedGame", this.stopGame);
    window.clearTimeout(this.openTimeout);
  },
});
