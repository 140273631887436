class OptionsStorage {
  private storage: any;

  constructor() {
    try {
      if (window.localStorage) {
        this.storage = window.localStorage;
      }
    } catch (e) {
      this.storage = {
        items: [],
        getItem: function (key: string) {
          return this.items[key];
        },
        setItem: function (key: string, value: string) {
          this.items[key] = value;
        }
      };
    }
  }

  public getItem(key: string) {
    return this.storage.getItem(key);
  }

  public setItem(key: string, value: string) {
    this.storage.setItem(key, value);
  }
}

export const optionsStorage = new OptionsStorage();