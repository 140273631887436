import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36a1862c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "doubling-desktop-mevaho-gold" }
const _hoisted_2 = { class: "doubling-desktop-mevaho-gold__scoreboard" }
const _hoisted_3 = {
  key: 0,
  class: "doubling-desktop-mevaho-gold__wrapper"
}
const _hoisted_4 = { class: "doubling-desktop-mevaho-gold__info" }
const _hoisted_5 = { class: "doubling-desktop-mevaho-gold__bottom" }
const _hoisted_6 = { class: "doubling-desktop-mevaho-gold__doublingMevahoGold" }
const _hoisted_7 = { class: "doubling-desktop-mevaho-gold__wrapperGold" }
const _hoisted_8 = { class: "doubling-desktop-mevaho-gold__group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoublingHeader = _resolveComponent("DoublingHeader")!
  const _component_ScoreboardWrapper = _resolveComponent("ScoreboardWrapper")!
  const _component_InputFlame = _resolveComponent("InputFlame")!
  const _component_InputNice = _resolveComponent("InputNice")!
  const _component_DoublingBoxesMevahoGold = _resolveComponent("DoublingBoxesMevahoGold")!
  const _component_LastBoxesRow = _resolveComponent("LastBoxesRow")!
  const _component_ControlPanel = _resolveComponent("ControlPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DoublingHeader),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ScoreboardWrapper)
    ]),
    (_ctx.scoreboardMode === 'fruit')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("information.fruit.isDoublingModeMevahoMode")), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expectedWinnings, (item, idx) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
                (_ctx.isInRange(idx))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_ctx.fireproof && _ctx.fireproof === idx)
                        ? (_openBlock(), _createBlock(_component_InputFlame, {
                            key: 0,
                            smallFont: "",
                            ultraSmallFont: _ctx.hasBigNumber,
                            value: item,
                            color: idx === _ctx.stepCount ? 'yellow' : '',
                            style: _normalizeStyle({ order: _ctx.expectedWinnings.length - idx }),
                            class: _normalizeClass({ '--opacity': idx > _ctx.stepCount }),
                            animation: _ctx.stepCount === _ctx.fireproof
                          }, null, 8, ["ultraSmallFont", "value", "color", "style", "class", "animation"]))
                        : (_openBlock(), _createBlock(_component_InputNice, {
                            key: 1,
                            smallFont: "",
                            ultraSmallFont: _ctx.hasBigNumber,
                            value: item,
                            color: idx === _ctx.stepCount ? 'yellow' : '',
                            style: _normalizeStyle({ order: _ctx.expectedWinnings.length - idx }),
                            class: _normalizeClass({ '--opacity': idx > _ctx.stepCount })
                          }, null, 8, ["ultraSmallFont", "value", "color", "style", "class"]))
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ])
        ])
      ]),
      _createVNode(_component_DoublingBoxesMevahoGold),
      _createVNode(_component_LastBoxesRow)
    ]),
    _createVNode(_component_ControlPanel, {
      class: "doubling-desktop-mevaho-gold__panel",
      compact: ""
    })
  ]))
}