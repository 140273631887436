
import { defineComponent, PropType } from "vue";
import { formatMoney } from "@/utils";

export default defineComponent({
  name: "button-control",
  props: {
    value: {
      type: Number,
      required: true,
    },
    color: String as PropType<"pink" | "blue" | "orange">,
    glow: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssColor(): object {
      return {
        "--pink": this.color === "pink",
        "--blue": this.color === "blue",
        "--orange": this.color === "orange",
      };
    },
    formatMoney() {
      return formatMoney(this.value);
    },
  },
});
