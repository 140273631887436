import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a01132a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "combination-button__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileButton = _resolveComponent("MobileButton")!

  return (_ctx.isMobile)
    ? (_openBlock(), _createBlock(_component_MobileButton, {
        key: 0,
        onClick: _ctx.combinationSelection,
        disabled: 
      _ctx.controlDisabled ||
      _ctx.isDoublingOn ||
      _ctx.makeTicketDisabled ||
      _ctx.isBetstop ||
      _ctx.disconnected ||
      _ctx.promoIsOn
    ,
        text: _ctx.$t('chooseCombination'),
        color: _ctx.buttonColor
      }, null, 8, ["onClick", "disabled", "text", "color"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.combinationSelection && _ctx.combinationSelection(...args))),
        class: _normalizeClass(["combination-button", {
      '--disabled':
        _ctx.controlDisabled ||
        _ctx.isDoublingOn ||
        _ctx.makeTicketDisabled ||
        _ctx.isBetstop ||
        _ctx.disconnected ||
        _ctx.promoIsOn,
    }])
      }, [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("chooseCombination")), 1)
      ], 2))
}