
import { defineComponent, PropType } from "vue";
import MainContainer from "@/components/MainContainer.vue";
import MenuWrapper from "@/components/MenuWrapper.vue";
import DoublingHeader from "@/components/DoublingHeader.vue";
import InputNice from "@/components/UI/InputNice.vue";
import InputFlame from "@/components/UI/InputFlame.vue";
import store from "@/store";
import DoublingBoxes from "@/components/DoublingBoxes.vue";
import ControlPanel from "@/components/Panels/ControlPanel.vue";
import LastBoxes from "@/components/LastBoxes.vue";

export default defineComponent({
  name: "doubling-desktop-khoja",
  props: {},
  components: {
    ControlPanel,
    DoublingBoxes,
    InputNice,
    DoublingHeader,
    MenuWrapper,
    MainContainer,
    LastBoxes,
    InputFlame,
  },
  computed: {
    lastPayout(): number {
      return store.state.ui.lastPayout;
    },
    expectedWinnings(): number[] {
      return store.state.doubling.expectedWinnings;
    },
    stepCount(): number {
      return store.state.doubling.countGame;
    },
    fireproof(): number | undefined {
      const fireproof = store.state.settings.doubling_game.fireproof_stage;
      return fireproof.enabled ? fireproof.game_number : undefined;
    },
  },
  methods: {},
});
