
import { defineComponent } from "vue";

export default defineComponent({
  name: "my-button",
  props: {
    text: String,
    type: String,
    color: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stylesCss(): object {
      return {
        "--secondary": this.type === "secondary",
        "--disabled": this.disabled,
        "--green": this.color === "green",
      };
    },
  },
});
