import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/images/icons/wallet-icon.svg'
import _imports_1 from '@/images/icons/wallet-icon-white.svg'


const _withScopeId = n => (_pushScopeId("data-v-d48fe466"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-replenish" }
const _hoisted_2 = { class: "modal-replenish__title" }
const _hoisted_3 = {
  key: 0,
  class: "modal-replenish__image",
  src: _imports_0,
  alt: "wallet"
}
const _hoisted_4 = {
  key: 3,
  class: "modal-replenish__image",
  src: _imports_1,
  alt: "wallet"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyButton = _resolveComponent("MyButton")!
  const _component_BurgerModal = _resolveComponent("BurgerModal")!

  return (_openBlock(), _createBlock(_component_BurgerModal, {
    "show-close": true,
    open: _ctx.open,
    onClose: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("modalReplenishTitle")), 1),
        (!_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("img", _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.isSupportPostMessage)
          ? (_openBlock(), _createBlock(_component_MyButton, {
              key: 1,
              onClick: _ctx.closeModalPostMessage,
              class: "modal-replenish__button",
              text: _ctx.$t('replenish')
            }, null, 8, ["onClick", "text"]))
          : (_openBlock(), _createBlock(_component_MyButton, {
              key: 2,
              onClick: _ctx.closeModal,
              class: "modal-replenish__button",
              text: "Ok"
            }, null, 8, ["onClick"])),
        (_ctx.isMobile)
          ? (_openBlock(), _createElementBlock("img", _hoisted_4))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["open", "onClose"]))
}