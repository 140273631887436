
import { defineComponent } from "vue";
import CircleButton from "@/components/Panels/CircleButton.vue";
import store from "@/store";
import { AudioUtils } from "@/utils";

export default defineComponent({
  name: "nominal-control",
  components: { CircleButton },
  props: {},
  computed: {
    nominals(): number[] {
      return store.getters.chipNominalsArray;
    },
    currentNominal(): number | undefined {
      return store.state.createTicket.amount;
    },
    formatNominal(): number | undefined {
      return store.state.createTicket.amount ? store.state.createTicket.amount / 100 : undefined;
    },
    controlDisabled(): boolean {
      return store.state.ui.controlDisabled;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    makeTicketDisabled(): boolean {
      return store.state.ui.makeTicketDisabled;
    },
    disconnected(): boolean {
      return !store.state.connected;
    },
    promoIsOn(): boolean {
      return store.state.ui.promoIsOn;
    },
  },
  methods: {
    nominalSelectionPlus() {
      AudioUtils.playSound("pressing-bet-amount-button", 0, 1);
      const list = this.nominals;
      let idx = list.findIndex((el) => el === this.currentNominal);
      if (idx === -1 || idx === list.length - 1) {
        store.dispatch("setNewNominal", list[0]);
      } else {
        store.dispatch("setNewNominal", list[idx + 1]);
      }
    },
    nominalSelectionMinus() {
      AudioUtils.playSound("pressing-bet-amount-button", 0, 1);
      const list = this.nominals;
      let idx = list.findIndex((el) => el === this.currentNominal);
      if (idx === -1 || idx === 0) {
        store.dispatch("setNewNominal", list[list.length - 1]);
      } else {
        store.dispatch("setNewNominal", list[idx - 1]);
      }
    },
  },
});
