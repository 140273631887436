
import { defineComponent } from "vue";
import store from "@/store";
import BasicButton from "@/components/Panels/BasicButton.vue";
import { AudioUtils } from "@/utils";

export default defineComponent({
  name: "information-button",
  components: { BasicButton },
  props: {},
  computed: {},
  methods: {
    openModal() {
      AudioUtils.playSound("button-press-sound", 0);
      store.dispatch("toggleModal", "payments");
    },
  },
});
