import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4ea5642"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-payments" }
const _hoisted_2 = { class: "modal-payments__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MevahoPayments = _resolveComponent("MevahoPayments")!
  const _component_InfoModal = _resolveComponent("InfoModal")!

  return (_openBlock(), _createBlock(_component_InfoModal, {
    "show-close": true,
    open: _ctx.open,
    onClose: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("modalPaymentsTitle")), 1),
        _createVNode(_component_MevahoPayments)
      ])
    ]),
    _: 1
  }, 8, ["open", "onClose"]))
}