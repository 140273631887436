import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-flame", _ctx.stylesCss])
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["input-flame__value", { ['--small']: _ctx.smallFont, ['--ultraSmall']: _ctx.ultraSmallFont }])
    }, _toDisplayString(_ctx.formatValue), 3)
  ], 2))
}