import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c3ee057"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fruit-cell__container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fruit-cell", _ctx.styles])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (typeof _ctx.digit === 'number')
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: require(`@/images/fruits/fruit-${_ctx.digit}.png`),
            class: "fruit-cell__item"
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ])
  ], 2))
}