
import { defineComponent } from "vue";
import store from "@/store";
import { BonusAnim } from "@/types/bonus";
import { AudioUtils } from "@/utils";

export default defineComponent({
  name: "pamir-cell",
  components: {},
  props: {
    digit: Number,
    animation: {
      type: Boolean,
      default: false,
    },
    glow: {
      type: Boolean,
      default: false,
    },
    isBonusInBonus: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      required: true,
    },
  },
  computed: {
    styles(): object {
      return {
        "--glow": this.animation && this.glow,
        "--opacity": this.animation && !this.glow,
      };
    },
    bonusAnim(): BonusAnim | undefined {
      return store.state.bonus.animDoublingBonus;
    },
    isAnimTurnOn(): boolean {
      return this.animation && this.glow;
    },
    isBonusGame(): boolean {
      return store.state.ui.showBonusPersons;
    },
    imgPath(): string {
      if (this.isBonusGame) {
        return require(`@/images/pamir/gold/${
          this.animation && this.glow ? "anim-" + this.digit : "static-" + this.digit
        }.webp`);
      } else {
        return require(`@/images/pamir/${
          this.animation && this.glow ? "anim-" + this.digit : "static-" + this.digit
        }.webp`);
      }
    },
  },
  watch: {
    isAnimTurnOn: function (n) {
      if (n === true && this.bonusAnim && this.digit === 10 && !this.isBonusInBonus) {
        AudioUtils.playSound("getting-free-tickets-short-pamir-legends", 0);
      }
    },
  },
});
