
import { defineComponent, PropType } from "vue";
import store from "@/store";

export default defineComponent({
  name: "apply-doubling",
  props: {
    type: String as PropType<"plus" | "minus">,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stylesMobile(): object {
      return {
        "--disabled": this.hiddenMobile,
        "--blink": !this.hiddenMobile,
      };
    },
    stylesDesktop(): object {
      return {
        "--disabled": this.hiddenDesktop,
        "--blink": !this.hiddenDesktop,
      };
    },
    isDoublingDisabled(): boolean {
      return store.state.ui.isDoublingDisabled;
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    isFruitDoubling(): boolean {
      return store.state.userData.settings.mevaho_mode === "fruit" && this.isDoublingOn;
    },
    disconnected(): boolean {
      return !store.state.connected;
    },
    hiddenMobile(): boolean {
      return (
        this.disabled ||
        this.isDoublingDisabled ||
        this.isBetstop ||
        this.isFruitDoubling ||
        this.disconnected
      );
    },
    hiddenDesktop(): boolean {
      return (
        this.disabled ||
        this.isDoublingDisabled ||
        this.isBetstop ||
        this.isFruitDoubling ||
        this.disconnected
      );
    },
  },
  methods: {},
});
